import request from "@/utils/request";
/**
 * 获取充值记录列表
 */
export function getRechargeList(gardNo, fromRealname, state, startAddTime, endAddTime, size, page) {
  return request.get("/oilCard/api/user/recharges", {
  	
  	params: {
  		gardNo:gardNo,
  		fromRealname:fromRealname,
  		state:state,
  		startAddTime:startAddTime,
  		endAddTime:endAddTime,
   	 	size:size,
		page:page
    } 
  });
}




/**
 * 获取充值记录详情信息
 */
export function getRecharge(id) {
   return request.get("/oilCard/api/recharge/"+id, {});
}

/**
 * 确定充值
 */
export function getGasConfirmTransfer(no, type, balance, userId, gasCardId) {
   return request.put("/oilCard/api/gas-card/rechange?no="+ no +"&type="+ type +"&balance="+ balance +"&userId="+ userId +"&gasCardId="+ gasCardId, {});
}
