import request from "@/utils/request";

/**
 * 获取自有卡余额
 */
export function getGasBillfreeBalance(params) {
  return request.get("/oilCard/api/gas-card/amount", { params: { params } });
}


/**
 * 获取转入卡余额
 */
export function getGasBillTransfer() {
  return request.get("/oilCard/api/invoicable-amount", {});
}


/**
 * 获取可开票金额
 */
export function getGasBillIinvoice() {
  return request.get("/oilCard/api/userRecharge", {});
}

/**
 * 获取可开票金额
 */
export function getGasTodayBalance() {
  return request.get("/oilCard/api/user/order-amount", {});
}


/**
 * 折线图
 */

export function getGaslengend() {
  return request.get("/oilCard/api/user/gas-summaries", {});
}
























