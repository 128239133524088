<template>
  <div class="app-main">
  	 <div class="body mgin">
				     <el-form inline class="my-form">
					    	<el-form-item label="提现时间：">
					        <time-picker @timeValue="getValue(arguments)"></time-picker>
					      </el-form-item>
				      <el-form-item label="提现单号：">
				        <el-input size="small" placeholder=""></el-input>
				      </el-form-item>
				      <el-form-item label="提现用户：">
				        <el-input size="small" placeholder=""></el-input>
				      </el-form-item>
				      <el-form-item>
				        <el-button size="small" type="primary">查询</el-button>
				        <el-button size="small">重置</el-button>
				        <el-button size="small">导出</el-button>
				        <el-button size="small">导出Excel</el-button>
				        <el-button type="primary" size="small" plain @click.stop="returnfalse">返回</el-button>
				      </el-form-item>
				    </el-form>
				    
        </div>
        
        <div class="body mgin">
			    <div class="btn_po">
	  		  		<el-radio-group v-model="cardType">
					          <el-radio-button label="全部"></el-radio-button>
					          <el-radio-button label="待审核"></el-radio-button>
				            <el-radio-button label="审核成功"></el-radio-button>
				            <el-radio-button label="审核失败"></el-radio-button>
					    </el-radio-group>
			  	</div>
			  	<page-table :data="tableData" :columns="columns" :pagination="pagination" v-if="cardType=='全部'" :loading="loading">
			      <el-table-column label="操作" align="center">
			        <template slot-scope="scope">
			             <el-tag
						          :type="scope.row.state === '2' ? 'primary' : 'success'" style="cursor: pointer;" @click.stop="widthDrawDetail(scope.$index, scope.row)"
						       >{{scope.row.state === '2' ? '审核' : '查看'}}</el-tag>
			        </template>
			      </el-table-column>
			    </page-table>
			  	<page-table :data="tableDataSec" :columns="columns1" :pagination="pagination" v-if="cardType=='待审核'" :loading="loading">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		             <el-tag
						          :type="scope.row.state === '2' ? 'primary' : 'success'" style="cursor: pointer;" @click.stop="widthDrawDetail(scope.$index, scope.row)"
						          disable-transitions>{{scope.row.state === '2' ? '审核' : '查看'}}</el-tag>
		          </template>
		        </el-table-column>
		     </page-table>
		    <page-table :data="tableDataThi" :columns="columns2" :pagination="pagination" v-if="cardType=='审核成功'" :loading="loading">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		             <el-tag
						          :type="scope.row.state === '2' ? 'primary' : 'success'" style="cursor: pointer;" @click.stop="widthDrawDetail(scope.$index, scope.row)"
						          disable-transitions>{{scope.row.state === '2' ? '审核' : '查看'}}</el-tag>
		          </template>
		        </el-table-column>
		     </page-table>
		    <page-table :data="tableDataFou" :columns="columns3" :pagination="pagination" v-if="cardType=='审核失败'" :loading="loading">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		            <el-tag
						          :type="scope.row.state === '2' ? 'primary' : 'success'" style="cursor: pointer;" @click.stop="widthDrawDetail(scope.$index, scope.row)"
						          disable-transitions>{{scope.row.state === '2' ? '审核' : '查看'}}</el-tag>
		          </template>
		        </el-table-column>
		    </page-table>
	  	</div>

  	  <v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" :dialogFooter='dialogFooter' @update:dialogVisible="dialogVisibles">
  	 		   <div class="dialog_content" v-show="oildetailStatus" v-for="item in this.widthDrawData" >
	            <v-step :itemData="widthDrawData"></v-step>
	            <div style="padding-top: 20px;">
            	      <p>提现金额：{{item.price}}元</p>
				    	   	  <p>手续费：{{item.servicePrice}}</p>
				    	   	  <p>申请时间：{{item.addTime}}</p>
				    	   	  <p>到账时间：{{item.finishTime}}</p>
				    	   	  <p>提现账户：{{item.user.realname}}</p>
				    	   	  <p>提现单号：{{item.no}}</p>
	            </div>
	     	  </div>
     </v-dialog>
  </div>
</template>
<script>
	import { getWithdrawList, getWithdraw, auditWithdraw } from "@/api/withdraw";
	import pageTable from '../../components/pageTable'
	import timePicker from '../../components/timePicker'
  import VDialog from '../../components/dialog'
	import VStep from '../../components/step'
  export default {
    name: 'withdrawDetail',
    data() {
      return {
       	cardType: '全部',
        tableData: [],
        widthDrawData:[],
        tableDataSec: [],
        tableDataThi: [],
        tableDataFou: [],
        columns: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '提现单号',
            value: 'no'
          },
          {
            name: '提现用户',
            value: 'user'
          },
          {
            name: '金额',
            value: 'price'
          },
          {
            name: '状态',
            value: 'withdrawstate'
          },
          {
            name: '失败原因',
            value: 'failureMsg'
          }
        ],
        columns1: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '提现单号',
            value: 'no'
          },
          {
            name: '提现用户',
            value: 'user'
          },
          {
            name: '金额',
            value: 'price'
          },
          {
            name: '状态',
            value: 'withdrawstate'
          },
          {
            name: '失败原因',
            value: 'failureMsg'
          }
        ],
        columns2: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '提现单号',
            value: 'no'
          },
          {
            name: '提现用户',
            value: 'user'
          },
          {
            name: '金额',
            value: 'price'
          },
          {
            name: '状态',
            value: 'withdrawstate'
          },
          {
            name: '失败原因',
            value: 'failureMsg'
          }
        ],
        columns3: [
         {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '提现单号',
            value: 'no'
          },
          {
            name: '提现用户',
            value: 'user'
          },
          {
            name: '金额',
            value: 'price'
          },
          {
            name: '状态',
            value: 'withdrawstate'
          },
          {
            name: '失败原因',
            value: 'failureMsg'
          }
        ],
        loading:false,
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size: 0
        },
        dialogVisible:false,
        oildetailStatus:false,
        dialogInfo:'',
        dialogFooter:false,
      }
    },
    components: { pageTable, timePicker, VDialog, VStep},
    created() {
      document.title = '提现管理'
      this.$store.dispatch('SetNavbar', [{ meta: { title: '管理中心' }, path: '/oilCard-manage/index' }, { meta: { title: '油卡管理' }, path: '/oilCard-manage/manage' }, { meta: { title: '流水记录' }, path: '/oilCard-manage/flow-detail' }, { meta: { title: '提现管理' } }])
    },
    methods:{
    	returnfalse(){
    		  this.$router.go(-1);
    	},
    	getValue(data){
//  		 console.log(data)
    	},
	    withdrawList(){
 	        return new Promise((resolve, reject) => {
       	  this.loading = true;
		        getWithdrawList().then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	 this.tableData = res.content
			            	 this.tableDataSec=this.tableData.filter(item => item.state===2)
							       this.tableDataThi=this.tableData.filter(item => item.state===1)
							       this.tableDataFou=this.tableData.filter(item => item.state===-1)
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		          })
		      })
      },
      widthDrawDetail(event, value){
      	this.widthDrawData=[value]
      	this.dialogVisible=true
        this.oildetailStatus=true
        this.dialogInfo='消费提现-到微信零钱'
      },
      //子组件传 过来的 数据
		  dialogVisibles(v){
		      this.dialogVisible = v
		  }
    },
    mounted(){
       this.withdrawList()
    }
  }
</script>
<style scoped lang="scss">
.btn_po{
	margin: 0px 10px 10px 0;
}
.el-form-item{
   margin:0 38px 0px 0;
}
</style>