<template>
  <div class="app-main">
    <!-- <div class="head mgin">
      <div class="btns">
           <el-button plain  type="primary" size="mini" @click.stop="goDetail" >导出excel</el-button>
      </div>
    </div> -->
    <div class="body mgin">
		    	 <div class="btn_po">
		  		  		<el-radio-group v-model="cardType">
		  		  			<el-radio-button label="全部"></el-radio-button>
					        <el-radio-button label="处理中"></el-radio-button>
					        <el-radio-button label="开票成功"></el-radio-button>
				          <el-radio-button label="开票失败"></el-radio-button>
						    </el-radio-group>
					</div>
          <page-table :data="tableData" :columns="columns" :pagination="pagination" v-if="cardType=='全部'" :loading="loading">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		                <el-tag @click.stop="checkAudit(scope.$index, scope.row)" class="btnlist">详情</el-tag>
		          </template>
		        </el-table-column>
		      </page-table>
		      <page-table :data="tableDataFir" :columns="columns1" :pagination="pagination" v-if="cardType=='处理中'" :loading="loading">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		               <el-tag @click.stop="checkAudit(scope.$index, scope.row)" class="btnlist">详情</el-tag>
		          </template>
		        </el-table-column>
		      </page-table>
		      <page-table :data="tableDataSec" :columns="columns2" :pagination="pagination" v-if="cardType=='开票成功'" :loading="loading">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		                <el-tag @click.stop="checkAudit(scope.$index, scope.row)" class="btnlist">详情</el-tag>
		          </template>
		        </el-table-column>
		      </page-table>
		      <page-table :data="tableDataThi" :columns="columns3" :pagination="pagination" v-if="cardType=='开票失败'" :loading="loading">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		                <el-tag @click.stop="checkAudit(scope.$index, scope.row)" class="btnlist">详情</el-tag>
		          </template>
		        </el-table-column>
		      </page-table>
    </div>
  </div>
</template>
<script>
	import { getGasBillRecord } from "@/api/invoice";
  import pageTable from '../../components/pageTable'
  export default {
    name: 'manage',
    data() {
      return {
        cardType:'全部',
        tableData: [],
        columns: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '单位名称',
            value: 'invoiceLookUp'
          },
          {
            name: '纳税人识别号',
            value: 'identifier'
          },    
          {
            name: '开票金额（元）',
            value: 'amount'
          },
          {
            name: '开票状态',
            value: 'invoiceStatus'
          },
          {
            name: '失败原因',
            value: 'failReason'
          }
        ],
        columns1: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '单位名称',
            value: 'invoiceLookUp'
          },
          {
            name: '纳税人识别号',
            value: 'identifier'
          },    
          {
            name: '开票金额（元）',
            value: 'amount'
          },
          {
            name: '开票状态',
            value: 'invoiceStatus'
          },
          {
            name: '失败原因',
            value: 'failReason'
          }
        ],
    columns2: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '单位名称',
            value: 'invoiceLookUp'
          },
          {
            name: '纳税人识别号',
            value: 'identifier'
          },    
          {
            name: '开票金额（元）',
            value: 'amount'
          },
          {
            name: '开票状态',
            value: 'invoiceStatus'
          },
          {
            name: '失败原因',
            value: 'failReason'
          }
        ],
        columns3: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '单位名称',
            value: 'invoiceLookUp'
          },
          {
            name: '纳税人识别号',
            value: 'identifier'
          },    
          {
            name: '开票金额（元）',
            value: 'amount'
          },
          {
            name: '开票状态',
            value: 'invoiceStatus'
          },
          {
            name: '失败原因',
            value: 'failReason'
          }
        ],
        loading:false,
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size: 0
        },
        radio:"1",
        invoiceTitle:'',
        identityNum:'',
        openBank:'',
        invoiceAddress:'',
        invoiceAmount:'',
        mailingAddress:'',
        consignee:'',
        chargedCall:'',

        dialogVisible: false,
	      dialogInfo:'',
        modifyStatus:false,
	      rechargeStatus:true,
	      cancelStatus:false,
	      
	      getUserRechargeMoney:'',
	      getInvoicableMoney:''
      }
    },
    methods: {
      checkAudit(event, value){
         console.log(value.user.userId)
      	 this.$router.push({path:'/oilCard-manage/invoicemanangeDetail',query:{id:value.invoiceId, invoiceDataUserid:value.user.userId}}) 
      },
      getGasBillRecordList(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasBillRecord().then(res => {
			              this.loading = false;
				            if(res.code === 1){
				            	 let firArr=[]
				            	 let secArr=[]
				            	 let thiArr=[]
				            	 this.tableData = res.content
				            	 console.log(this.tableData)
                       this.tableDataFir=this.tableData.filter(item => item.status==3)
							         this.tableDataThi=this.tableData.filter(item => item.status==4)
							             
							        thiArr=this.tableData.filter(item => item.status == 1)
										  firArr=this.tableData.filter(item => item.status == 2)
										  secArr=this.tableData.filter(item => item.status == 5)
										  thiArr.push(...firArr);
											thiArr.push(...secArr);
											this.tableDataSec=thiArr
									
 
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      }

    },
    components: { pageTable },
    filters: {
      money(n) {
        let num = n.toFixed(2)
        let numInt = num.split('.')[0]
        let numFloat = num.split('.')[1]
        let numIntArr = numInt.split('')
        let numIntArrReverse = numIntArr.reverse()
        let arr = []
        numIntArrReverse.map((item, index) => {
          arr.push(item)
          if ((index + 1) % 3 == 0) {
            arr.push(',')
          }
        })
        return arr.reverse().join('') + '.' + numFloat
      }
    },
    mounted(){
       this.getGasBillRecordList()

    }
  }
</script>
<style lang="scss" scoped>
  .head {
    background: #fff;
    display: flex;
    padding-left: 20px;
    align-items: center;
    height: 100px;
    margin-bottom:20px;
    .money {
      vertical-align: baseline;
      margin: 0 20px;
      span {
        font-size: 20px;
      }
    }
  }
  .el-form-item {
    margin:15px 45px 0 0;
  }
  .all-money {
    margin: 5px 0;
    font-size: 14px;
    color: #817979;
    text-align: right;
  }
  .allMoneyText{
  	 font-size: 16px;
  	 text-align: right;
  }
  .btn_po{
  	margin: 0 10px 10px 0;
  }
  .btns{
    display: flex;
  	justify-content: flex-end;
  }
  .dialog_list{
  	display: flex;
  	padding-bottom: 10px;
  	label{
  		width: 20%;
  		line-height: 35px;
  	}
  }
  .invoiceTitle{
	  text-align: center;
	  padding-bottom: 20px;
  }
  .whyBtn{
  	background: #000;
  	border-radius: 50%;
  	width: 30px;
  	height: 30px;
  	color: #fff;
  	text-align: center;
  	line-height: 30px;
  	font-size: 18px;
  	margin-right: 20px;
  	cursor:pointer;
  }
</style>