<template>
	<div class="app-main">
	  <div class="body mgin" style="padding: 15px 0px 15px 0px;">                    
		    <el-form inline class="my-form">
		      <el-form-item label="交易单号：">
		          <el-input size="small" placeholder="" v-model="tradeNo"></el-input>
		      </el-form-item>
		      <el-form-item label="手机号：">
		          <el-input size="small" placeholder="" v-model="telPhone"></el-input>
		      </el-form-item>
		      <el-form-item label="订单状态：">
		            <v-select :selectData="oilCardStatus" :selectID="selectID" @choonseGrageEvent="chooseBrand"></v-select> 
		      </el-form-item>
		  
		      <el-form-item>
		        <el-button size="small" type="primary" @click.stop="findData">查询</el-button>
		        <el-button size="small" @click.stop="resetBtn">重置</el-button>
		        <el-button size="small" placeholder="">导出</el-button>
		      </el-form-item>
		    </el-form>
       </div>
       <div class="body m-t20 mgin" style="padding-top: 0;">
       	    <page-table :data="tableData" :columns="columns" :pagination="pagination" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
		      <el-table-column label="操作" align="center">
		        <template slot-scope="scope">
	                <el-tag @click.stop="editOildetail(scope.$index, scope.row)" class="btnlist">查看</el-tag>
	                <!-- <el-tag @click.stop="refundAmount" class="btnlist" style="color: red;">退款</el-tag>-->
		        </template>
		      </el-table-column>
		    </page-table>
       </div>
       
	   <v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" :dialogFooter='dialogFooter' @update:dialogVisible="dialogVisibles">
	     	   <div class="dialog_content" v-show="oildetailStatus" v-for="item in this.tableDataDeail" >
	     	   	  <p>加油站点：{{item.gasStation.name}}</p>
	    	   	  <p>加油地址：{{item.gasStation.address}}</p>
	     	      <p>加油金额：¥{{item.gasPrice.toFixed(2)}}元</p>
	    	   	  <p>当前状态：
	    	   	   <span v-if="item.state==1">支付成功</span>
		           <span v-if="item.state==0">等待支付</span>
		           <span v-if="item.state==-1">已取消</span>
		           <span v-if="item.state==-2">已退款</span>
	    	   	  </p>
	     	      <p>支付时间：{{item.addTime}}</p>
	    	   	  <p>油品类型：{{item.oilName}}</p>
	     	      <p>加油平台：
	     	               <span v-if="item.provider==1">车主邦</span>
				           <span v-if="item.provider==2">山东高速信联支付</span>
				           <span v-if="item.provider==3">万金油</span>
	     	      </p>
	    	   	  <p>加油抢号：{{item.gunNo}}号</p>
	    	   	  <p>账户余额支付：¥{{item.balancePrice.toFixed(2)}}元</p>
	     	      <p style="display： flex;">油卡余额支付：
	     	      	  <span v-for="list in gardsValue" >
	     	      	  	  <span>¥{{(item.gasPrice+item.balancePrice).toFixed(2)}}元</span>	 
			     	      <span>（卡尾号：{{list.gasCardNo.substr(list.gasCardNo.length - 4, 4)}}）</span>
	     	      	  </span>
	     	      	  <span v-if="!gardsValue.length">￥0.00</span>	 
	     	      </p>
	    	   	  <p>支付方式：{{item.payment}}</p>
	     	      <p>交易单号：{{item.no}}</p>
	     	   </div>
	    </v-dialog>
  </div>
</template>
<script>
  import { getGasOrderList, getGasOrder } from "@/api/order";
  import pageTable from '../../components/pageTable'
  import VDialog from '../../components/dialog'
  import VSelect from '../../components/select'
  export default {
    name: 'addOildetail',
    inject:['reload'],
    data() {
      return {
        tableData: [],
        tableDataDeail:[],
        gardsValue:[],
        oilCardStatus: [{
          value: 1,
          label: '支付成功'
        }, {
          value: 0,
          label: '等待支付'
        }, {
          value: -1,
          label: '已关闭'
        }],
       
        columns: [
          {
            name: '交易时间',
            value: 'updateTime'
          },
          {
            name: '交易单号',
            value: 'no'
          },
          {
            name: '加油金额（元）',
            value: 'orderPrice',
            width: '180'
          },
          {
            name: '手机号码',
            value: 'addoilTelphone'
          },
          {
            name: '操作用户',
            value: 'user'
          },
          {
            name: '订单状态',
            value: 'paystate'
          },
          {
            name: '加油站',
            value: 'oilDetailGasStation',
            width: '280'
          }
        ],
        loading:false,
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size: 0
        },
        dialogVisible:false,
        oildetailStatus:false,
        dialogInfo:'',
        dialogFooter:false,
        tradeNo:'',
        telPhone:'',
        selectID:""
        
      }
    },
    components: { pageTable,VDialog,VSelect }, 
    created() {
      document.title = '加油订单'
      this.$store.dispatch('SetNavbar', [{ meta: { title: '管理中心' }, path: '/oilCard-manage/index' }, { meta: { title: '油卡管理' }, path: '/oilCard-manage/manage' }, { meta: { title: '加油订单' } }])
    },
    methods:{
    	findData(){
    		console.log(this.tradeNo)
    		 this.oilOrderList(this.tradeNo, this.telPhone, this.selectID, this.pagination.pageSize,this.pagination.currPage)
    	},
    	resetBtn(){
    	  var self=this
    	  self.reload() 
    	},
	    oilOrderList(no, mobile, state, size, page){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		        getGasOrderList(no, mobile, state, size, page).then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	console.log(res)
			            	this.tableData = res.content
			            	this.pagination.total = res.totalElements
				            this.pagination.size = res.totalPages	
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		               reject(error)
		         })
		      })
      },
      
      pageSizeNum(value){
      	  console.log(value)
      	  this.pagination.pageSize=value
      	 this.oilOrderList(this.tradeNo, this.telPhone, this.selectID, this.pagination.pageSize,this.pagination.currPage)
      },
      
      currentPageNum(event, value){
      	  console.log(event)
      	  this.pagination.currPage=event
      	  this.oilOrderList(this.tradeNo, this.telPhone, this.selectID, this.pagination.pageSize,this.pagination.currPage)
      },

      chooseBrand(value){
      	this.selectID=value
      },
      refundAmount(){
      	console.log(2222222)
      },
      editOildetail(event, value){
        this.tableDataDeail=[value]
        console.log(value.gasCards)
        this.gardsValue= JSON.parse(value.gasCards)

      	this.dialogVisible=true
        this.oildetailStatus=true
        this.dialogInfo='加油详情'
        this.dialogFooter=false
      },
      //子组件传 过来的 数据
	  dialogVisibles(v){
	    this.dialogVisible = v
	  }
    },
    mounted(){
       this.oilOrderList(this.tradeNo, this.telPhone, this.selectID, this.pagination.pageSize,this.pagination.currPage)
    }
  }
</script>
<style scoped lang="scss">
.el-form-item{
   margin:0 45px 10px 0;
}
.dialog_content p{
	line-height: 25px;
}
</style>