<template>
  <div class="app-main">
        <div class="body mgin">
        	<div class="bg_top">
        		 <el-row>
						  <el-col :span="12">
						    <div class="bg_top_left">
						    	<span>油卡充值</span>
						    </div>
						  </el-col>
						  <el-col :span="12">
						  	  <div class="bg_top_right">
						  	  		<el-button type="primary" plain @click.stop="getValue">充值记录</el-button>
						  	  </div>
						  </el-col>
						</el-row>
        	</div>
			    <div class="btn_po">
	  		  	 <p>
	  		        <label>油卡编号：</label>
	  		        <span>{{this.no}}</span>
	  		  	 </p>
	  		  	 <p>
	  		  	 	 <label>*充值金额：</label>
	  		  	 	 <el-input placeholder="充值金额（元）" v-model="withdrawalCash" clearable></el-input>
	  		  	 </p>
	  		  	 <p>
	  		  	 	*最小充值金额为100元
	  		  	 </p>
	  		  	 <p>
	  		  	 	    <label>充值优惠：</label>
	  		  	 	    <el-radio v-model="radio" label="1">增票企业充值直返1%</el-radio>
	  		  	 	    <p style="margin-left： 100px;">
	  		  	 	          <el-radio v-model="radio" label="2">最高优惠加油充值直返9%，提供对账单</el-radio>
	  		  	 	    </p>
	  		  	 </p>
			  	</div>
			  	
			  	<div class="btn_pobot">
			  		 <p>*支付方式：</p>
				     <el-radio-group v-model="payment" size="medium">
					      <!-- <el-radio-button label="支付宝"></el-radio-button>
					      <el-radio-button label="微信"></el-radio-button> -->
					      <el-radio-button label="对公转账"></el-radio-button>
				     </el-radio-group>
				     <div class="paylist">
				     	    <div v-if="payment=='支付宝'">
     	    		    	    <el-radio v-model="paynum" label="1">
							     	    	<img src="../../assets/images/zfb.jpg" style="width: 100px; height: 100px" fit="contain">
							     	    </el-radio>
							     	    <div>
							     	    	<el-button type="primary" plain @click.stop="confirmBtn">确定</el-button>
					                <el-button plain @click.stop="cancelBtn">取消</el-button>
							     	    </div>
				     	    </div>
				     	    
				     		  <div v-if="payment=='微信'">
     	    		   			<el-radio v-model="paynumWX" label="1">
						     	    	<img src="../../assets/images/wx.jpg" style="width: 100px; height: 100px" fit="contain">
						     	    </el-radio>
						     	    <div>
						     	    	  <el-button type="primary" plain @click.stop="confirmBtn">确定</el-button>
					                <el-button plain @click.stop="cancelBtn">取消</el-button>
						     	    </div>
				     	    </div>
				     	    
				     </div>

				     <div v-if="payment=='对公转账'">
				     	       <v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" :widthStatus='widthStatus'  :dialogFooter='dialogFooter' @update:dialogVisible="dialogVisibles" 
											>
								  	 		   <div class="dialog_content" v-show="oildetailStatus" >
									            
									           <div class="invoice_content">
											    		  	 <div class="title">汇款信息</div>
														       <div class="title_info">
														       	  <p>
														       	  	<span class="invoiceTips">账号名称</span>
														       	  	<span class="invoiceinfo">江苏大件无忧物流科技发展有限公司</span>
														       	  </p>
                                     	<p>
														       	  	<span class="invoiceTips ">大件无忧对公账号</span>
														       	  	<span class="invoiceinfo">20390188000029939</span>
														       	  </p>
														         	<p>
														       	  	<span class="invoiceTips">开户行</span>
														       	  	<span class="invoiceinfo">江苏银行无锡天河支行</span>
														       	  </p>
														         	<p>
														       	  	<span class="invoiceTips">开户行行号</span>
														       	  	<span class="invoiceinfo">313302001039</span>
														       	  </p>
                                    	<p>
														       	  	<span class="invoiceTips">注册地址</span>
														       	  	<span class="invoiceinfo">无锡市惠山区惠山大道88号明都大厦1号门504室（堰桥）</span>
														       	  </p>
														       	  <p>
														       	  	<span class="invoiceTips">汇款备注</span>
														       	  	<span class="invoiceinfo">汇款时,请详细填写汇款备注,以备大件物流查账,例如:大件物流共享加油小程序油卡充值,手机号:13771572666</span>
														       	  </p>
														       </div>
														       <div>
														       	*如有疑问，请您联系在线客服咨询 0510-82030388
														       </div>
											    	</div>
									     	  </div>
								     </v-dialog>
				     </div>
			  	</div>
	  	</div>
  </div>
</template>
<script>
	import { getGasConfirmTransfer } from "@/api/recharge";
	import pageTable from '../../components/pageTable'
	import timePicker from '../../components/timePicker'
  import VDialog from '../../components/dialog'
	import VStep from '../../components/step'
  export default {
    name: 'withdrawDetail',
    data() {
      return {
      	payment:'对公转账',
        dialogVisible:true,
        oildetailStatus:true,
        dialogInfo:'对公打款流程',
        dialogFooter:false,
        CashAccount:'',
        withdrawalCash:'',
        openBankName:'',
        openBank:'',
        openBankAccount:'',
        radio:"1",
        paynum:"1",
        paynumWX:"1",
        
        no:'',
	      userId:'',
				gasCardId:'',
				widthStatus:'60%',
      }
    },
    components: { pageTable, timePicker, VDialog, VStep},
    created() {
    //   document.title = '提现管理'
      this.$store.dispatch('SetNavbar', [{ meta: { title: '管理中心' }, path: '/oilCard-manage/index' }, { meta: { title: '油卡管理' }, path: '/oilCard-manage/manage' }, { meta: { title: '流水记录' }, path: '/oilCard-manage/flow-detail' }, { meta: { title: '提现管理' } }])
    },
    methods:{
    	getValue(data){
          this.$router.push({path:'/oilCard-manage/transferdetail'}) 
    	},
	    withdrawList(no, type, balance, userId, gasCardId){
 	        return new Promise((resolve, reject) => {
       	  this.loading = true;
		        getGasConfirmTransfer(no, type, balance, userId, gasCardId).then(res => {
		             this.loading = false;
			                this.$message({
							          message: '充值成功',
							          type: 'success'
							        });
			  
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		          })
		      })
      },
      confirmBtn(){
      	//进行请求
//         this.withdrawList(this.no, this.radio, this.withdrawalCash, this.userId, this.gasCardId)
           this.$message({
		          message: '微信和支付宝充值暂时不能充值，正在维护中......,请对公转账',
		          type: 'warning'
	         });
      },
      cancelBtn(){
	       this.withdrawalCash=""
      },
      //子组件传 过来的 数据
	    dialogVisibles(v){
	      this.dialogVisible = v
	    }
    },
    watch:{
    	payment(value){
    		 if(value=="对公转账"){
    		 	this.dialogVisible=true
          this.oildetailStatus=true
    		 }
    	}
    },
    mounted(){
        this.no = this.$route.query.no
	      this.userId = this.$route.query.userId
			  this.gasCardId = this.$route.query.gasCardId
    }
  }
</script>
<style scoped lang="scss">
.btn_po{
	margin: 50px;
	width: 30%;
  border-bottom: 1px solid #c8c8c8;
	p{
		display: flex;
		margin-bottom: 20px;
		label{
			width: 20%;
			line-height: 36px;
		}
		span{
			width: 20%;
      line-height: 40px;
      text-indent: 10px;
      color: #409EFF;
      cursor:pointer;
		}
	}
}
.el-form-item{
   margin:0 45px 0px 0;
}
.bg_top{
	background:#b3d8ff;
	height:80px;
	line-height:80px;
	.bg_top_left{
		font-size: 20px;
		color: #333;
		font-weight: bold;
		text-indent: 20px;
	}
	.bg_top_right{
		text-align: right;
    margin-right: 20px;
	}
}

.btn_pobot{
	margin: 50px;
	width: 30%;
	.paylist{
		display: flex;
	}
}
  .dialog_content {
    background: #fff;
    display: flex;
    padding-left: 20px;
    justify-content: center;
    .invoice_content{
      flex: 1;
      width: 50%;
      justify-content: center;
      align-items: center;
    
    	.title{
    		font-size: 14px;
    		padding-bottom: 10px;
    	}
    	.title_info{
    	  font-size: 14px;
        p{
          min-height: 60px;
        	display: flex;
        	.invoiceTips{
        		width: 20%;
        		background: #DDDDDD;
        		text-align: center;
        		line-height: 60px;
        	}
        	.invoiceinfo{
        		width: 80%;
        		padding: 18px;
        		border: 1px solid #dddddd;
        	
        	}
        }
    	}
    }
  }
</style>