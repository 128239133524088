<template>
  <div>
    <el-table
      :ref="tableName"
      :data="data"
      v-on="$listeners"
      v-bind="$attrs"
      v-loading="loading"
      border
      @selection-change="handleSelectionChange"
      :row-class-name="rowClassName"
      header-cell-class-name="header-cell"
    >
      <!--<slot name="check"></slot>
      <el-table-column v-if="selection" type="selection" width="55"></el-table-column>-->
       <el-table-column v-if="selection" type="selection" width="70"></el-table-column>
      <el-table-column
        v-for="(column, index) in columns"
        :width="column.width ? column.width : ''"
        :key="column.value"
        :label="column.name"
        :show-overflow-tooltip="false"
        :sortable="column.sort"
        :sort-method="column.sortMethod"
        align="center"
      >
        <template slot-scope="scope">
          <!--<span
            v-if="column.value==='index' && index === 0"
            v-html="scope.$index + pagination.currPage * pagination.pageSize + 1"
          ></span>-->
          <!--<div
            class="image-box"
            v-if="column.value === 'company'"
            v-html="scope.row[column.value].name"
          ></div>-->
       
          <div  v-if="column.value === 'oilCardGoflowbalance'">
             <span v-if="scope.row['outlay'] > 0">{{scope.row['outlay']}}</span>
             <span v-else-if="scope.row['receive'] > 0">{{scope.row['receive']}}</span>
             <span v-else-if="scope.row['outlay']== 0 && scope.row['receive']== 0">0</span>
          </div>
          
          <div
            class="image-box"
            v-else-if="column.value === 'user'"
            v-html="scope.row[column.value].realname"
          >
          </div>
          <div
            class="image-box"
            v-else-if="column.value === 'userPhone'"
            v-html="scope.row['user'].mobile"
          >
          </div>
          
          <div
            class="image-box"
            v-else-if="column.value === 'serialNumber'"
            v-html="index+1"
          >
          </div>
          <div
            v-else-if="column.value === 'addoilTelphone'"
            v-html="scope.row['user'].mobile"
          >
          </div>

          <div
            v-else-if="column.value === 'transferName'"
            v-html="scope.row['fromUser'].nickname"
          >
          </div>
          <div
            v-else-if="column.value === 'transferPhoneNum'"
            v-html="scope.row['fromUser'].mobile"
          >
          </div>
          <div
            v-else-if="column.value === 'transferfromUser'"
            v-html="scope.row['fromGasCard'].no"
          >
          </div>
          <div
            v-else-if="column.value === 'transfertoUser'"
            v-html="scope.row['toUser'].nickname"
          >
          </div>
          <div
            v-else-if="column.value === 'transfertoPhoneNum'"
            v-html="scope.row['toUser'].mobile"
          >
          </div>
           <div
            v-else-if="column.value === 'transfertoGasCard'"
            v-html="scope.row['toGasCard'].no"
          >
          </div>
          <div
            v-else-if="column.value === 'cardsRealname'"
            v-html="scope.row['user'].realname"
          >
          </div>
           <div
            v-else-if="column.value === 'cardsMobile'"
            v-html="scope.row['user'].mobile"
          >
          </div>
          <div
            v-else-if="column.value === 'cardsCompany'"
            v-html="scope.row['fromUser'].realname"
          >
          </div>
          <div
            v-else-if="column.value === 'cardsPhone'"
            v-html="scope.row['fromUser'].mobile"
          >
          </div>
          <div
            v-else-if="column.value === 'rechargeOilNo'"
            v-html="scope.row['gasCard'].no"
          >
          </div>
               <div
            v-else-if="column.value === 'invoiceMain'"
            v-html="scope.row['user'].nickname"
          >
          </div>
          <div
            v-else-if="column.value === 'invoiceOpenDetail'"
            v-html="scope.row['no']"
          >
          </div>
          <div
            v-else-if="column.value === 'invoiceOpenOrderPrice'"
            v-html="scope.row['price']"
          >
          </div>
          <div
            v-else-if="column.value === 'invoiceOpenPrice'"
            v-html="scope.row['price']"
          >
          </div>

          
          <div
            v-else-if="column.value === 'invoiceAmount'"
          >
             <span v-if="scope.row['ifInvoice']==true"  v-html="scope.row['price']"></span>
             <span else>0</span>
          </div>

          <div
            v-else-if="column.value === 'oilCardflowBalance'"
          >
             <span v-show="scope.row['outlay'] > 0" v-html="scope.row['outlay']"></span>
             <span v-show="scope.row['receive'] > 0">{{scope.row['receive']}}</span>
          </div>
    
          <div
            v-else-if="column.value === 'oilCardFlow'"
            v-html="scope.row['user'].mobile"
          >
          </div>
          
          <div
            v-else-if="column.value === 'oilCardGoflow'"
          >
             <span v-show="scope.row['outlay'] > 0">支出</span>
             <span v-show="scope.row['receive'] > 0">收入</span>
             <span v-show="scope.row['outlay']== 0 && scope.row['receive']== 0"></span>
          </div>
          
          <div
            v-else-if="column.value === 'invoicePrice'"
            v-html="scope.row['price']"
          >
          </div>
          
          
          <div
            class="image-box"
            v-else-if="column.value==='type'"
          >
           <span v-if="scope.row[column.value]=='1'">个人自己卡</span>
           <span v-if="scope.row[column.value]=='2'">企业自己卡</span>
           <span v-if="scope.row[column.value]=='3'">分配卡</span>
           <span v-if="scope.row[column.value]=='4'">转账卡</span>
          </div>
          <div
            class="image-box"
            v-else-if="column.value==='oilStationtype'"
          >
           <span v-if="scope.row['type']=='1'">中石油</span>
           <span v-if="scope.row['type']=='2'">中石化</span>
           <span v-if="scope.row['type']=='3'">壳牌</span>
           <span v-if="scope.row['type']=='4'">其他</span>
          </div>
          
          <div
            class="image-box"
            v-else-if="column.value==='oilStationbrand_type'"
          >
           <span v-if="scope.row['brandType']=='1'">国营央企</span>
           <span v-if="scope.row['brandType']=='2'">民营私企</span>
           <span v-if="scope.row['brandType']=='3'">外企/合资</span>
          </div>
          
          <div
            class="image-box"
            v-else-if="column.value==='way'"
          >
           <span v-if="scope.row[column.value]=='1'">高速</span>
           <span v-if="scope.row[column.value]=='2'">国道</span>
           <span v-if="scope.row[column.value]=='3'">其他</span>
          </div>
          <div
            class="image-box"
            v-else-if="column.value==='provider'"
          >
           <span v-if="scope.row['provider']=='1'">车主邦</span>
           <span v-if="scope.row['provider']=='2'">山东高速信联支付</span>
           <span v-if="scope.row['provider']=='3'">万金油</span>
          </div>
          <div
            class="image-box"
            v-else-if="column.value==='paystate'"
          >
           <span v-if="scope.row['state']=='1'">支付成功</span>
           <span v-if="scope.row['state']=='0'">等待支付</span>
           <span v-if="scope.row['state']=='-1'">已取消</span>
           <span v-if="scope.row['state']=='-2'">已退款</span>
          </div>
          <div
            class="image-box"
            v-else-if="column.value==='withdrawstate'"
          >
           <span v-if="scope.row['state']=='2'">待审核</span>
           <span v-if="scope.row['state']=='0'">提现中</span>
           <span v-if="scope.row['state']=='1'">已成功</span>
           <span v-if="scope.row['state']=='-1'">失败</span>
          </div>
          <div
            class="image-box"
            v-else-if="column.value==='rechargeState'"
          >
           <span v-if="scope.row['state']=='0'">等待支付</span>
           <span v-if="scope.row['state']=='1'">充值成功</span>
           <span v-if="scope.row['state']=='-1'">已关闭</span>
          </div>
          <div
            class="image-box"
            v-else-if="column.value==='transferstate'"
          >
           <span v-if="scope.row['state']=='0'">未完成</span>
           <span v-if="scope.row['state']=='1'">已成功</span>
          </div>

          <div
            class="image-box"
            v-else-if="column.value==='invoiceStatus'"
          >
           <span v-if="scope.row['status']=='1'">已开发票（邮寄）</span>
           <span v-if="scope.row['status']=='2'">已开发票（未邮寄）</span>
           <span v-if="scope.row['status']=='3'">申请中</span>
           <span v-if="scope.row['status']=='4'">开票失败</span>
           <span v-if="scope.row['status']=='5'">已开发票（无需邮寄）</span>
          </div>

          <div
            v-else-if="column.value === 'oilDetailGasStation'"
            v-html="scope.row['gasStation'].name"
          >
          </div>
          <span v-else v-html="scope.row[column.value]"></span>
        </template>
      </el-table-column>
      <slot></slot>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        style="text-align:right;"
        background
        :layout="pagination.layout"
        :total="pagination.total"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :current-page="pagination.currPage + 1"
        @size-change="handleSizeChange"
        @current-change="currentChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: [Array, Object],
      required: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    imageWidth: {
      type: String,
      default: '120px'
    },
    pagination: {
      type: [Array, Object],
      required: true
    },
    tableName: {
      type: String,
      default: 'pageTable'
    },
    selection: {
      type: Boolean,
      default: false
    },
    rowClassName: {
      type: Function
    },
    defaultSort: {
      type: Object
    },
    loading:{
    	type: Boolean,
      default: false
    }
  },
  created() {},
  computed: {},
  methods: {
    formatImage(src) {
      return '<img src="' + src + '" />'
    },

    currentChange(index, row) {
      this.$emit('current-change', index-1, row)

      
    },
    
    handleSizeChange(size) {
      this.$emit('size-change', size)
    },
    
    setCurrent() {
      const n = this.tableName
      this.$refs[n].setCurrentRow()
    },
      handleSelectionChange(val) {
      console.log(val)
      this.$emit('handleSelectionChange', val)
    },
  }
}
</script>
<style lang="scss">
.pagination-container {
  padding: 20px;
}
.image-box {
  img {
    width: 100%;
  }
}
.el-table__header .header-cell {
  background: #f5f7fa;
}
</style>