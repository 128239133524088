<template>
  <div class="app-main">
  	<div>
  		  <div class="business-card">
			      <div class="card-body">
						      <div class="card-body-itemlist">
	            				<div class="card-body-item-name">增票充值金额</div>
					            <div class="card-body-item-money">¥{{this.balance}}元</div>
			            </div>
						      <div class="card-body-itemlist">
	            				<div class="card-body-item-name">消费金额</div>
					            <div class="card-body-item-money">¥{{this.consumption}}元</div>
			            </div>
						      <div class="card-body-itemlist">
	            				<div class="card-body-item-name">已开票金额</div>
					            <div class="card-body-item-money">¥{{this.openInvoiceBalance}}元</div>
			            </div>
						      <div class="card-body-itemlist" style="border-right:none">
		            			<div class="card-body-item-name">最大可开票金额</div>
						          <div class="card-body-item-money">¥{{this.canInvoiceBalance}}元</div>
			            </div>
			      </div>
			      
			      <div class="invoiceBill">
			      	 <div class="title">可开票金额说明</div>
			      	 <div>1.未开票消费金额=消费金额-已开票金额&nbsp;2.当未开票消费金额>（增票充值金额总额-已开票金额），则可开票金额=（增票充值金额总额-已开票金额）3.当未开票消费金额<（增票充值金额总额-已开票金额），则可开票金额=未开票消费金额4.自由卡分配或者消费的金额可开票5.充值时选择无需开票的金额不可开票6.充值时赠送的金额不可开票7.转账卡消费金额不可开票</div>
			      	 <div>例如:</div>
			      	 <div>充值1000赠送10元,可开票金额为1000元，若分配出去1010元，则最大可开票金额为1000元，若分配出去500元，则最大可开票金额为500元</div>
			      </div>
	    </div>
  		
			<div class="head mgin">
			    	<div class="invoice_content">
			    		  	<div class="invoiceTime">
			    		  		<div style="flex: 1;">
			    		  			 合同有效期:
			    		  			 <span style="color:red;">
			    		  			 	 {{this.contract}}
			    		  			 </span>
			    		  		</div>
			    		  		<!--<el-link :href="downloadUrl" :underline="false" style="margin-left:15px" >-->
				    		  		<div class="timeColorStatus" style="text-decoration: underline;justify-content: flex-end;cursor: pointer;" @click.stop="uploadfile">
				    		  			   大件物流共享加油（增值税发票）线下协议
				    		  		</div>
			    		  	<!--	</el-link>-->
			    		  	</div>
               
						      <div class="invoiceList">
						      	  <div class="invoiceTitle">发票类型</div>
						          <div class="invoiceContent">
						          	<el-radio v-model="radiom" label="1">增值税专用发票</el-radio>
						          </div>
						          <div class="invoiceTitle">发票抬头</div>
						          <div class="invoiceContent">
						            	<input type="text" placeholder="请输入内容" v-model="invoiceLookUp">
						          </div>
						          <div class="invoiceTitle">申请开票金额（元）</div>
						          <div class="invoiceContent" style="border-right: none">
						          	<input type="text" placeholder="请输入内容" v-model="invoiceAmountbalance" @input="checkMobile">
						          </div>
						      </div>
						      
						      <div class="invoiceList COLORBG">
						      	  <div class="invoiceTitle">纳税人识别号</div>
						          <div class="invoiceContent ">
						          	<input type="text" placeholder="请输入内容" v-model="identifier" class="COLORBG" maxlength="20">
						          </div>
						          <div class="invoiceTitle">开户行及账号</div>
						          <div class="invoiceContent ">
						          	<input type="text" placeholder="请输入内容" v-model="bankName" class="COLORBG" @input="checkMobile">
						          </div>
						          <div class="invoiceTitle">地址</div>
						          <div class="invoiceContent " style="border-right: none">
						          	<input type="text" placeholder="请输入内容" v-model="address" class="COLORBG">
						          </div>
						      </div>
						      
						      <div class="invoiceList">
						      	  <div class="invoiceTitle">收取发票地址</div>
						          <div class="invoiceContent">
						          	<input type="text" placeholder="请输入内容" v-model="mailAddress">
						          </div>
						          <div class="invoiceTitle">收货人</div>
						          <div class="invoiceContent">
						          	<input type="text" placeholder="请输入内容" v-model="name">
						          </div>
						          <div class="invoiceTitle">收货电话</div>
						          <div class="invoiceContent" style="border-right: none">
						          	<input type="text" placeholder="请输入内容" v-model.number="phone" maxlength="11" oninput = "value=value.replace(/[^\d]/g,'')" >
						          </div>
						      </div>
						      
						      <div class="invoiceList COLORBG">
						      	  <div class="invoiceTitle">备注</div>
						          <div class="invoiceContent" style="border-right: none">
						          	<input type="text" placeholder="请输入内容" v-model="remark" class="COLORBG">
						          </div>
						      </div>
						      <div class="btnStatus">
						      	   <el-button type="primary" round @click.stop="applyInvoice">申请开票</el-button>
						      </div>
			    	</div>
			    </div>
   </div>
	 <v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" @update:dialogVisible="dialogVisibles" @update:confirmdialogVisible="confrmdialogVisibles">
	     	   <div class="dialog_content" v-show="modifyStatus"></div>
	 </v-dialog>
  </div>
</template>
<script>
	import { getGasBillbalance, getGasBillConsumptionBalance, getapplyinvoice, getRechargeList, getGasBillContract } from "@/api/invoice";
  import pageTable from '../../components/pageTable'
  import timePicker from '../../components/timePicker'
  import VDialog from '../../components/dialog'
  import Clipboard from 'clipboard'
  export default {
    name: 'manage',
    data() {
      return {
        invoiceInfo:[],
        loading:false,
        pagination:{
        	
        },
        downloadUrl:'https://publicfile.js56918.com/%E5%A4%A7%E4%BB%B6%E6%97%A0%E5%BF%A7%E5%85%B1%E4%BA%AB%E5%8A%A0%E6%B2%B9%EF%BC%88%E5%A2%9E%E5%80%BC%E7%A8%8E%E5%8F%91%E7%A5%A8%EF%BC%89%E7%BA%BF%E4%B8%8B%E5%8D%8F%E8%AE%AE%EF%BC%88%E6%96%B0%EF%BC%89%281%29.docx',
        flagStatus:false,
        showPagination:false,
        radio:"1",
        radiom:'1',
        inputNo:'',
        inputCompany:'',
        desc:'',
        dialogVisible:false,
	    dialogInfo:'',
        modifyStatus:false,
        invoiceid:0,
        copyData:null,
        timeValueFir:'',
	    timeValueSec:'',
	    timeStatus:false,
	    auditStatus:"2",
	    consumption:'',
	    balance:'',
	    openInvoiceBalance:'',
	    canInvoiceBalance:'',
	    invoiceLookUp:'',
	    invoiceAmountbalance:'',
	    identifier:'',
	    bankName:'',
	    address:'',
	    mailAddress:'',
	    name:'',
	    phone:'',
	    remark:'',
	    gardNo:'',
	    fromRealname:'',
	    state:'',
	    startTime:'',
	    endTime:'',
	    contract:'',
	
		selection:true
      }
    },
    methods: {
      validateMobile(mobile) {
        return /1(3|4|5|6|7|8|9)\d{9}/.test(mobile)
      },
      
      uploadfile(){
      	window.location.href=this.downloadUrl
      	this.flagStatus=true
      	localStorage.setItem("uploadflagStatus",this.flagStatus)
      },
      checkMobile(){
        	this.invoiceAmountbalance = this.invoiceAmountbalance.replace(/[^\d]/g, '')
          this.bankName = this.bankName.replace(/[^\d]/g, '')
      },

      invoiceListDetailBalance(){
			return new Promise((resolve, reject) => {
			this.loading = true;
				getGasBillbalance().then(res => {
						this.loading = false
					if(res.code === 1){
							this.balance = res.content.balance.toFixed(2)
							this.openInvoiceBalance = res.content.openInvoiceBalance.toFixed(2)
							this.canInvoiceBalance = res.content.canInvoiceBalance.toFixed(2)
							if(res.content.balance==null){
								this.balance="0.00"
							}else if(res.content.openInvoiceBalance==null){
								this.openInvoiceBalance="0.00"
							}else if(res.content.canInvoiceBalance==null){
								this.canInvoiceBalance="0.00"
							}   
						}
						resolve(res)
					}).catch(error => {
						this.loading = false;
						reject(error)
					})
			})
      },

      invoiceListDetailConsumptionBalance(){
			return new Promise((resolve, reject) => {
			this.loading = true;
				getGasBillConsumptionBalance().then(res => {
						this.loading = false;
						if(res.code === 1){
					this.consumption=res.content
						}
						resolve(res)
					}).catch(error => {
						this.loading = false;
						reject(error)
					})
			})
      },
      getapplyinvoicelist(invoiceLookUp, identifier, bankName, address, amount, mailAddress, name, phone, remark){
     		 var that=this
 	          return new Promise((resolve, reject) => {
	       	    this.loading = true;
			        getapplyinvoice(invoiceLookUp, identifier, bankName, address, amount, mailAddress, name, phone, remark).then(res => {
			             this.loading = false;
				            if(res.code === 1){
				                   this.$message({
										message: '申请发票成功',
										type: 'success'
									});
										       
									localStorage.removeItem("uploadflagStatus")
									setTimeout(function(){ 
										that.$router.push({path:'/oilCard-manage/invoiceRecord'})
									}, 500);  
				            }
				            resolve(res)
			         }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			          })
		        })
       },

    	invoiceListDetail(id){
      	    var tableArr=[]
 	          return new Promise((resolve, reject) => {
	       	    this.loading = true;
			        getGasBill(id).then(res => {
			             this.loading = false;
				            if(res.code === 1){
				            	 tableArr.push(res.content)
				            	 this.invoiceInfo=tableArr		
				            }
				            resolve(res)
			         }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			          })
		        })
        },
      
		applyInvoice(){
			  console.log(localStorage.getItem('uploadflagStatus'))
				if(localStorage.getItem('uploadflagStatus') !== 'true'){
				    this.$message.warning(' 请先下载大件物流共享加油（增值税发票）线下协议!');
	                return
	          
				}else if(Number(this.invoiceAmountbalance) > Number(this.canInvoiceBalance)){

		        	this.$message.warning('申请开票金额不能大于最大可开票金额!');
	           return

	       }else if(this.invoiceLookUp=="" || this.invoiceAmountbalance=="" || this.identifier=="" || this.bankName=="" ){
	          	 
	          	 this.$message.warning('申请开票务必把内容填齐！');
	          	 return
	          	 
	       }else if(this.address=="" || this.mailAddress=='' || this.name=="" || this.phone=="" || this.phone=="" || this.remark==""){
	          
	          this.$message.warning('申请开票务必把内容填齐！');
	          return
	           
      	 }else{
	       	
	         	this.getapplyinvoicelist(this.invoiceLookUp, this.identifier, this.bankName, this.address, this.invoiceAmountbalance, this.mailAddress, this.name, this.phone, this.remark)
	     }
		},
			
		getRechargeListLIST(){
 	      return new Promise((resolve, reject) => {
       	  this.loading = true;
		        getRechargeList().then(res => {
		             this.loading = false;
			            if(res.code === 1){
			          			this.tableData = res.content
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		          })
		      })
        },

    	invoiceListDetail(){
 	          return new Promise((resolve, reject) => {
	       	    this.loading = true;
	       	    var dataContract=""
			        getGasBillContract().then(res => {
			             this.loading = false;
				            if(res.code === 1){
				              dataContract=res.content.contractValidity
				              if(dataContract=="" || dataContract==null){
				              	 this.contract="未签订合同，请下载合同"
				              	
				              }else{
				              	 this.contract=res.content.contractValidity
				              }
				            }
				            resolve(res)
			         }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			          })
		        })
      },
     //子组件传 过来的 数据
	   dialogVisibles(v){
	      this.dialogVisible = v
	   },
	   
	   confrmdialogVisibles(v){
	    	 if(this.modifyStatus==true){
	       	 	this.ModifyMoneyfun(this.no, this.inputcause, this.inputMoney)
	    	 	  this.dialogVisible = v
	    	 }else if(this.rechargeStatus==true){
		    	 	if(this.rechargeBalanceValue=="" || this.rechargeBalanceValue==null){
		    	 		  this.$message('充值金额不能为空！')
		    	 	}else{
		 		   	 	 this.rechargeBalanceMoney(this.no, this.radio, this.rechargeBalanceValue, this.rechargeUserId, this.rechargeGasCardId)
		 	         this.dialogVisible = v
		    	 	}
	    	 }
	    }
    },
    components: { pageTable, timePicker, VDialog },
    filters: {
      money(n) {
        let num = n.toFixed(2)
        let numInt = num.split('.')[0]
        let numFloat = num.split('.')[1]
        let numIntArr = numInt.split('')
        let numIntArrReverse = numIntArr.reverse()
        let arr = []
        numIntArrReverse.map((item, index) => {
          arr.push(item)
          if ((index + 1) % 3 == 0) {
            arr.push(',')
          }
        })
        return arr.reverse().join('') + '.' + numFloat
      }
   },
   mounted(){
//  	this.invoiceid = this.$route.query.id
//  	console.log(this.$route.query.id)
//      this.invoiceListDetail(this.invoiceid)
        this.invoiceListDetailBalance()
        this.invoiceListDetailConsumptionBalance()
        this.getRechargeListLIST()
        this.invoiceListDetail()
    }
  }
</script>
<style lang="scss" scoped>
  .business-card{
  	margin: 20px;
  	.card-body{
  		border:1px solid #ccc;
  		.card-body-itemlist{
	  		width: 25%;
	  		text-align: center;
	  		border-right: 1px solid #ccc;
	  		padding:15px 0;
	  		.card-body-item-name{
	  			color:#409EFF;
	  			font-size: 18px;
	  		}
	  		.card-body-item-money{
	  			font-size: 28px;
	  			color: #333;
	  		}
	  	}
  	}
  	.invoiceBill{
  		padding: 20px 50px;
  		border: 1px solid #ccc;
    	border-top: none;
  		.title{
  			color: #333;
  			font-size: 14px;
  		}
  	}
  }
  .head {
    background: #fff;
    display: flex;
    padding-left: 20px;
    justify-content: center;
    .invoice_content{
      flex: 1;
      justify-content: center;
      align-items: center;
    	padding:20px;
    	.invoiceTime{
    		display: flex;
    		padding-bottom: 20px;
    		line-height: 35px;
    		padding-right: 15px;
    		font-size: 14px;
    		.timeColorStatus{
    			color: #409EFF;
    			font-size: 14px;
    		}
    	}
      .invoiceList{
      	 display: flex;
      	 height: 40px;
      	 line-height: 40px;
      	 text-align: center;
         border: 1px solid #C8C8C8;
         border-bottom: none;
      	 .invoiceTitle{
      	 	border-right: 1px solid #C8C8C8;
      	 	width: 12%;
      	 }
      	 .invoiceContent{
      	 		width: 23.3%;
      	 		border-right: 1px solid #C8C8C8;
      	 		input{
      	 			    width: 100%;
							    height: 35px;
							    line-height: 35px;
							    border: none;
							    outline: none;
							    text-indent: 15px;
      	 		}
      	 }
      	 .colorSTATUS{
      	 	  color: #008000;
      	 }
      }
      .COLORBG{
      	background: #dddddd;
      }
      .btnStatus{
      	text-align: center;
      	margin-top:20px;
      }
    }
  }
  .el-button.is-round {
    border-radius: 20px;
    padding: 5px 15px;
  }
  .btn_po{
  	width: 80px;
  	border-top: 2px solid #409EFF;
  	height: 35px;
  	line-height: 35px;
  	text-align: center;
  	span{
  		 font-size: 14px;
  	}
  }
  .pagination-container{
  	display: none;
  }

</style>