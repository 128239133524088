<template>
  <div class="steplist">
  	<label>现在状态</label>
		<el-steps :space="200" :active="activeNum+1" align-center>
			  <el-step title="发起提现" :description="itemData[0].addTime"></el-step>
			  <el-step title="处理中"  description=""></el-step>
			  <el-step title="到账"  :description="itemData[0].finishTime"></el-step>
		</el-steps>
  </div>
</template>
<script>
  export default {
    name: "step",
    data() {
      return {
      	 timeFirst:'',
      	 timeSecond:'',
      	 activeNum:1,
      }
    },
    props: {
      itemData: [Array, Object]
    },
    created() {
      	console.log(this.itemData)
    }
  }
</script>
<style  lang="scss">
.steplist {
	.el-step__line{
  	background: #409EFF;
  }
  .el-step__title{
  	font-size: 14px;
  }
  .el-step__icon{
		width: 18px;
		height: 18px;
		background: #409EFF;
	}	
	.el-step__head.is-finish {
    color: #409EFF;
    border-color: #409EFF;
  }
  .el-step__title.is-finish{
  	 color: #C8C8C8;
  }
  .el-step__description.is-finish{
  	 color: #C8C8C8;
  }
  .el-step.is-center .el-step__description {
    padding-left: 18%;
    padding-right: 18%;
  }
  .el-step__title.is-process {
    font-weight: 700;
    color: #409EFF;
  }
  .el-step__head.is-process {
    color: #fff;
    border-color: #c8c8c8;
  }
  .el-step__icon.is-text {
    border-radius: 50%;
    border-color: inherit;
  }
}
</style>
