import request from "@/utils/request";

/**
 * 获取发票列表
 */
//export function getInvoiceBillList(params) {
//return request.get("/api/gas-bills", { params: { params } });
//}

/**
 * 获取发票详情金额
 */
export function getGasBillbalance() {
  return request.get("/oilCard/api/userRecharge", {});
}

/**
 * 获取发票消费金额
 */
export function getGasBillConsumptionBalance() {
  return request.get("/oilCard/api/invoicable-amount", {});
}

/**
 * 获取申请发票
 */
export function getapplyinvoice(invoiceLookUp, identifier, bankName, address, amount, mailAddress, name, phone, remark) {
	return request({
	    url: "/oilCard/api/invoice",
	    method: "post",
	    data: {
	        type:'1',
		    invoiceLookUp:invoiceLookUp,
		    identifier:identifier,
		    bankName:bankName,
		    address:address,
		    amount:amount,
		    mailAddress:mailAddress,
		    name:name,
		    phone:phone,
		    remark:remark
	    }
	})
}

/**
 * 获取发票记录
 */
export function getGasBillRecord() {
   return request.get("/oilCard/api/invoice/list/company", {});
}

/**
 * 获取发票详情
 */
export function getGasBillInvoiceDetail(id) {
   return request.get("/oilCard/api/invoice/"+id, {});
}

/**
 * 获取列表
 */
//export function getRechargeList(gardNo, fromRealname, state, startAddTime, endAddTime) {
//return request.get("/api/user/recharges?gardNo="+ gardNo +"&fromRealname="+ fromRealname +"&state="+ state +"&startAddTime="+ startAddTime +"&endAddTime="+ endAddTime, {});
//}


/**
 * 获取列表可开票明细
 */
export function getRechargeList() {
  return request.get("/oilCard/api/user/invoice/recharges", {});
}

/**
 * 合同有效期
 */
export function getGasBillContract() {
   return request.get("/oilCard/api/user/info",{});
}

/**
 * 可开票列表
 */


 export function getInvoiceUserIDList(userId) {
	return request.get("/oilCard/api/user/invoice/recharges/"+ userId, {});
  }
