<template>
  <div class="app-main" >
  	<div v-for="(item,index) in this.tableDetailArr" :key="index">
		    <div class="head mgin">
		    	<div class="invoice_content">
		    		  	 <div class="title">基本信息</div>
					       <div class="title_info">
					       	  <p>
					       	  	<span class="invoiceTips">申请时间</span>
					       	  	<span class="invoiceinfo">{{item.addTime}}</span>
					       	  </p>
					         	<p>
					       	  	<span class="invoiceTips">发票状态</span>
					       	  	<span class="invoiceinfo">
				       	  		         <span v-if="item.status=='1'">已开发票（邮寄）</span>
										           <span v-if="item.status=='2'">已开发票（未邮寄）</span>
										           <span v-if="item.status=='3'">未开发票</span>
										           <span v-if="item.status=='4'">开票失败</span>
										           <span v-if="item.status=='5'">已开发票（无需邮寄）</span>
					       	  	</span>
					       	  </p>
					         	<p>
					       	  	<span class="invoiceTips">开票信息</span>
					       	  	<span class="invoiceinfo">
					       	  	         <span v-if="item.status=='1'">已开发票（邮寄）</span>
										           <span v-if="item.status=='2'">已开发票（未邮寄）</span>
										           <span v-if="item.status=='3'">未开发票</span>
										           <span v-if="item.status=='4'">开票失败</span>
										           <span v-if="item.status=='5'">已开发票（无需邮寄）</span>
					       	  	</span>
					       	  </p>
					       	  <p>
					       	  	<span class="invoiceTips">发票类型</span>
					       	  	<span class="invoiceinfo">增值税专用发票</span>
					       	  </p>
					       	  <p>
					       	  	<span class="invoiceTips">开票金额</span>
					       	  	<span class="invoiceinfo">¥{{item.amount.toFixed(2)}}元</span>
					       	  </p>
					       </div>
		    	</div>
		  
		      <div class="invoice_content">
		    		  	<div class="title">开票信息</div>
					      <div class="title_info">
					       	  <p>
					       	  	<span class="invoiceTips">发票抬头</span>
					       	  	<span class="invoiceinfo">{{item.invoiceLookUp}}</span>
					       	  </p>
					         	<p>
					       	  	<span class="invoiceTips">纳税人识别号</span>
					       	  	<span class="invoiceinfo">{{item.identifier}}</span>
					       	  </p>
					       	<p>
					       	  	<span class="invoiceTips">开户行及账号</span>
					       	  	<span class="invoiceinfo">{{item.name}}</span>
					       	  </p>
					       	  <p>
					       	  	<span class="invoiceTips">地址及电话</span>
					       	  	<span class="invoiceinfo">{{item.address}}</span>
					       	  </p>
					      </div>
		    	</div>
		    </div>
		    
		    <div class="head mgin">
		    	<div class="invoice_content">
		    		  	 <div class="title">邮寄信息</div>
					       <div class="title_info">
					       	  <p>
					       	  	<span class="invoiceTips">邮寄地址</span>
					       	  	<span class="invoiceinfo">{{item.mailAddress}}</span>
					       	  </p>
					         	<p>
					       	  	<span class="invoiceTips">收货人</span>
					       	  	<span class="invoiceinfo">{{item.name}}</span>
					       	  </p>
					       </div>
		    	</div>
		  
		      <div class="invoice_content">
		    		  	<div class="title">合同快递信息</div>
					      <div class="title_info">
					       	  <p>
					       	  	<span class="invoiceTips">快递公司</span>
					       	  	<span class="invoiceinfo"></span>
					       	  </p>
					         	<p>
					       	  	<span class="invoiceTips">快递单号</span>
					       	  	<span class="invoiceinfo">
					       	  	    <el-button type="primary" round size style="text-align: right;" @click.stop="handleCopy()">复制</el-button>
					       	  	</span>
					       	  </p>
					      </div>
		    	</div>
		    </div>
		    
		    <div class="head mgin" style="margin-bottom: 20px;">
		    	<div class="invoice_content">
		    		  	<div class="title">发票快递信息</div>
					      <div class="title_info">
					       	  <p>
					       	  	<span class="invoiceTips">快递公司</span>
					       	  	<span class="invoiceinfo">{{item.deliveryCompany}}</span>
					       	  </p>
					         	<p>
					       	  	<span class="invoiceTips">快递单号</span>
					       	  	<span class="invoiceinfo"> 
					       	  		  {{item.deliveryNo}}
					       	  	    <el-button type="primary" round size style="text-align: right;" @click.stop="handleCopy()">复制</el-button>
					       	  	</span>
					       	  </p>
					      </div>
		    	</div>
		    </div>


	    <div class="body mgin" style="padding: 0px 0px 20px 0px;">
				    	 <div class="btn_po">
				  		     <span>可开票明细</span>
							</div>
							<page-table :data="tableData" :columns="columns"  @size-change="pageSizeNum"  @current-change="currentPageNum" :pagination="pagination"  @handleSelectionChange="handleSelectionChange" :loading="loading" :selection="selection" ></page-table>
			    </div>
				<div style="padding:20px">
                    <span>待开票金额：</span>
					<span style="color:red;font-size:18px">{{ invoiceAllMoney }}</span>元
				 </div> 

	  </div>
  </div>
</template>
<script>
  import { getGasBillInvoiceDetail, getInvoiceUserIDList } from "@/api/invoice";
  import pageTable from '../../components/pageTable'
  export default {
    name: 'manage',
    data() {
      return {
        cardType: '全部',
        tableData: [],
        columns: [
          {
            name: '订单编号',
            value: 'invoiceOpenDetail'
          },
          {
            name: '开票主体',
            value: 'invoiceMain'
          },
          {
            name: '类型',
            value: 'category'
          },    
          {
            name: '订单实付金额',
            value: 'invoiceOpenOrderPrice'
          },
          {
            name: '可开票金额(元)',
            value: 'invoiceOpenPrice'
          },
          {
            name: '原始订单编号',
            value: 'no'
          },
          {
            name: '实例订单ID',
            value: 'rechargeId'
          },
          {
            name: '订单支付时间',
            value: 'updateTime'
          }
        ],
        radio:"1",
        inputNo:'',
        inputCompany:'',
        desc:'',
        dialogVisible:false,
	    dialogInfo:'',
        modifyStatus:false,
        invoiceid:0,
        tableDetailArr:[],
		selection:true,
	    pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 3,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage:0,
          size: 0
        },
	    invoiceAllMoney:0.00,
		invoiceOpenUserID:''
      }
    },
    methods: {
    	handleCopy(value){
      	 this.copyData = value
         this.copy(this.copyData)
      },
      
      copy(data){
        let url = data;
        let oInput = document.createElement('input');
        oInput.value = url;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象;
        console.log(oInput.value)
        document.execCommand("Copy"); // 执行浏览器复制命令
        this.$message({
          message: '复制成功',
          type: 'success'
        });
        oInput.remove()
     },
     pageSizeNum(value){
        // console.log(value)
        // this.pagination.pageSize=value
     },

     currentPageNum(event, value){
        // this.pagination.currPage=event
        // console.log(this.pagination.currPage)
     },

      handleSelectionChange(value){
        console.log(value)
        let sum=0
		if(value.length > 0){
			value.forEach((column, index) => {
				sum+=Number(column.price)
				console.log(sum)
				this.invoiceAllMoney=sum.toFixed(2)
			})
		}else{
            this.invoiceAllMoney=0.00
		}
	  },
      getGasBillInvoiceDetailList(id){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasBillInvoiceDetail(id).then(res => {
			              this.loading = false;
				            if(res.code === 1){
				            	 this.tableDetailArr.push(res.content)
				            //    console.log(this.tableDetailArr)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

      getInvoiceUserIDListData(id){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getInvoiceUserIDList(id).then(res => {
			              this.loading = false;
				            if(res.code === 1){
				            	 this.tableData=res.content
				                console.log(this.tableData)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

      //子组件传 过来的 数据
	    dialogVisibles(v){
	      this.dialogVisible = v
	    },
	    confrmdialogVisibles(v){
	    	 if(this.modifyStatus==true){
	       	 	this.ModifyMoneyfun(this.no, this.inputcause, this.inputMoney)
	    	 	  this.dialogVisible = v
	    	 }else if(this.rechargeStatus==true){
		    	 	if(this.rechargeBalanceValue=="" || this.rechargeBalanceValue==null){
		    	 		  this.$message('充值金额不能为空！')
		    	 	}else{
		 		   	 	 this.rechargeBalanceMoney(this.no, this.radio, this.rechargeBalanceValue, this.rechargeUserId, this.rechargeGasCardId)
		 	         this.dialogVisible = v
		    	 	}
	    	 }
	    }
    },
    components: { pageTable },
    filters: {
      money(n) {
        let num = n.toFixed(2)
        let numInt = num.split('.')[0]
        let numFloat = num.split('.')[1]
        let numIntArr = numInt.split('')
        let numIntArrReverse = numIntArr.reverse()
        let arr = []
        numIntArrReverse.map((item, index) => {
          arr.push(item)
          if ((index + 1) % 3 == 0) {
            arr.push(',')
          }
        })
        return arr.reverse().join('') + '.' + numFloat
      }
    },
    mounted(){
    	 this.invoiceid = this.$route.query.id
    	 console.log(this.$route.query.id)
		 this.getGasBillInvoiceDetailList(this.invoiceid)
		 this.invoiceOpenUserID = this.$route.query.invoiceDataUserid
    	 console.log(this.invoiceOpenUserID)
		 this.getInvoiceUserIDListData(this.invoiceOpenUserID)
    }
  }
</script>
<style lang="scss" scoped>
  .head {
    background: #fff;
    display: flex;
    padding-left: 20px;
    justify-content: center;
    .invoice_content{
      flex: 1;
      width: 50%;
      justify-content: center;
      align-items: center;
    	padding:20px;
    	.title{
    		font-size: 14px;
    	}
    	.title_info{
    	  font-size: 14px;
        p{
        	line-height: 40px;
			    height: 40px;
			    display: flex;
        	.invoiceTips{
        		width: 20%;
        		background: #DDDDDD;
        		text-align: center;
        	}
        	.invoiceinfo{
        		width: 60%;
        		border: 1px solid #dddddd;
        		text-indent: 10px;
        	}
        }
      }
    }
  }
  .el-button.is-round {
    border-radius: 20px;
    padding: 5px 15px;
  }
  .btn_po{
  	width: 80px;
  	border-top: 2px solid #409EFF;
  	height: 35px;
  	line-height: 35px;
  	text-align: center;
  	span{
  		 font-size: 14px;
  	}
  }
</style>