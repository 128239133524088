import request from "@/utils/request";
/**
 * 获取加油卡列表
 */
//export function getGasCardList(params) {
//return request.get("/api/user/gas-cards", { params: { params } });
//}
/**
 * 获取加油卡详情信息
 */

//export function getGasCard(id) {
//return request.get("/api/gas-card/"+id, {});
//}

//export function getGasCard(userId, fromGasCardId, toGasCardId) {
//return request.get("/api/gas-bills?userId="+userId+"&fromGasCardId="+fromGasCardId+"&toGasCardId="+toGasCardId, {});
//}


export function getGasCard(userId, size, page) {
   return request.get("/oilCard/api/gas-card/"+userId+"/gas-bill", {
   	 params: {
	   	 	size:size,
			page:page
	    } 
   });
}

/**
   修改金额
 */
export function changeBalance(nonumber, remarkesg, DoublebalanceMoney) {
  return request.put("/oilCard/api/gas-card-modify/amount?no="+nonumber+"&remark="+remarkesg+"&balance="+DoublebalanceMoney, {});
}

/**
  充值
 */
export function rechargeBalance(nonumber, typeValue, DoublebalanceMoney, rechargeUserId, rechargeGasCardId) {
  return request.put("/oilCard/api/gas-card/rechange?no="+nonumber+"&type="+typeValue+"&balance="+DoublebalanceMoney+"&userId="+rechargeUserId+"&gasCardId="+rechargeGasCardId, {});
}

/**
   冻结
 */
export function FrozenCard(no,state) {
   return request.get("/oilCard/api/freeze-card/"+ no +"/" + state, {});
}
/**
 查询
 */
//export function findData(no, realname, mobile, type) {
//	return request({
//	    url: "/api/gas-cards-condition",
//	    method: "post",
//	    data: {
//	        no: no,
//		    realname: realname,
//		    mobile: mobile,
//		    type: type
//	    }
//	})
//}

/**
 * 获取加油卡列表
 */
export function oilcardList(no, fromUsername, fromMobile, realname, mobile, giveAccept, size, page) {
	return request({
	    url: "/oilCard/api/gas-cards-company",
	    method: "post",
	    data: {
	        no: no,
		    fromUsername: fromUsername,
		    fromMobile: fromMobile,
		    realname:realname,
		    mobile:mobile,
		    giveAccept: giveAccept,
		    size:size, 
		    page:page
		    
	    }
	})
}

/**
 * 获取自有卡余额
 */
export function getGasBillfreeBalance() {
  return request.get("/oilCard/api/gas-card/amount", {});
}

/**
 * 我发出去余额
 */
export function getGasBillIssueBalance() {
  return request.get("/oilCard/api/gas-cards-receive-amount", {});
}

/**
 * 我收到的余额
 */
export function getGasBillreceviceBalance() {
  return request.get("/oilCard/api/gas-card-transfer/amount", {});
}

/**
 * 获取类型状态
 */
export function getGasBillContract() {
   return request.get("/oilCard/api/user/info",{});
}


/**
 * 获取发现接口
 */
export function oilCardsFind(username) {
	return request({
	    url: "/oilCard/api/account/find",
	    method: "post",
	    data: {
	      username:username
	    }
	})
}


/**
 * 获取发现接口
 */
export function oilCardsFindshudao(username) {
	return request({
	    url: "/oilCard/api/account/find",
	    method: "post",
	    data: {
	      username:username
	    }
	})
}

/**
 * 获取分配金额
// */
export function oilcardDistributionBalance(price, remark, fromGasCardId,toUserId) {
	return request({
	    url: "/oilCard/api/gas-transfer/assign",
	    method: "post",
	    data: {
	        category: '分配',
		    price: price,
		    remark: remark,
		    fromGasCardId: fromGasCardId,
		    toUserId:toUserId
	    }
	})
}

/**
   * 获取转账金额
*/
export function oilcardtransferAccBalance(price, remark, fromGasCardId, toUserId) {
	return request({
	    url: "/oilCard/api/gas-transfer/transfer",
	    method: "post",
	    data: {
	        category: '转账',
		    price: price,
		    remark: remark,
		    fromGasCardId: fromGasCardId,
		    toUserId:toUserId
	    }
	})
}

/**
 * 获取流水记录
 */
export function getGasflowRecord(gasCardId, startAddTime, endAddTime, userRealname, category, no, size, page) {
   return request.get("/oilCard/api/gas-card/"+gasCardId+"/gas-bill", {
   	    params: {
	   		startAddTime:startAddTime,
	   		endAddTime:endAddTime,
	   		userRealname:userRealname,
	   		category:category,
	   		no:no,
	   	 	size:size,
			page:page
	    } 
   });
}
