import request from "@/utils/request";

/**
 * 获取加油订单列表
 */
//export function getGasOrderList(params) {
//return request.get("/api/user/gas-orders", { params: { params } });
//}

//export function getGasOrderList(no, mobile, state) {
//	return request({
//	    url: "/api/company/gas-orders",
//	    method: "post",
//	    data: {
//	      no:no,
//		  mobile:mobile,
//		  state:state
//	    }
//	})
//}

/**
 * 获取加油订单列表
 */

export function getGasOrderList(no, mobile, state, size, page) {
  return request.get("/oilCard/api/user/gas-orders", {
  	 params: {
   		no:no,
   		mobile:mobile,
   		state:state,
   	 	size:size,
		page:page
     } 
  });
}

/**
 * 获取加油订单详情信息
 */
export function getGasOrder(id) {
  return request.get("/oilCard/api/gas-order/"+id, {});
}
