<template>
  <div class="app-main">
    <div class="business-card">
		      <div class="card-title">
			        <!-- <span class="business-name">{{this.company}}</span> -->
			     </div>
			     <div class="card-body">
			        <div v-for="(item,index) of cardData" :key="index" class="card-body-item">
			            <div class="card-body-item-name">{{item.name}}</div>
			            <div class="card-body-item-intro">{{item.intro}}</div>
			            <div class="card-body-item-money">¥{{item.money}} 
			          	<router-link to='/oilCard-manage/invoicemanange'>
			          	      <el-button type="primary" plain v-if="index==2" >开发票</el-button>
			          	</router-link>
			          </div>
			        </div>
			     </div>
    </div>
    <div class="business-card m-t20">
			  <my-legend></my-legend>
    </div>
  </div>
</template>
<script>
import { getGasBillfreeBalance, getGasBillTransfer, getGasBillIinvoice, getGasTodayBalance, getGaslengend } from "@/api/dataIndex";
import Legend from '../components/legend.vue'
export default {
  name: '',
  data() {
    return {
      cardData: [
        { name: '自有卡余额（元）', intro: '企业可加油分配金额', money: "" },
        { name: '消费余额（元）', intro: '收到全部转账卡余额', money: "" },
        { name: '可开票金额（元）', intro: '转账+加油金额', money: "" },
        { name: '今日加油金额（元）', intro: '', money: "" }
      ],
      echartsData: [
        { title: '加油金额' },
        { title: '提现金额' },
        { title: '分配金额' }
      ],
      company:'无锡市大件物流有限公司'
    }
  }, 
  created(){
   	   this.companyName=window.sessionStorage['username']
   	   this.getGasBillfreeBalanceList()
   	   this.getGasBillTransferList()
       this.getGasBillIinvoiceList()
       this.getGasTodayBalanceList()
       this.getGaslengendList()
  },
  methods:{
      returnInvoice(){
      	

      },
	    getGasBillfreeBalanceList(){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		          getGasBillfreeBalance().then(res => {
		             this.loading = false;
			            if(res.code === 1){
			               
			               this.company = res.content.company.name
			            	 this.cardData[0].money=res.content.balance.toFixed(2)
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },
	    
	    getGasBillTransferList(){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		          getGasBillTransfer().then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	this.cardData[1].money=res.content
			       
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },
	    
	    getGasBillIinvoiceList(){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		          getGasBillIinvoice().then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            		this.cardData[2].money=res.content.canInvoiceBalance.toFixed(2)
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },
      
      getGasTodayBalanceList(){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		          getGasTodayBalance().then(res => {
		             this.loading = false;
		             console.log(res)
			            if(res.code === 1){
			            		this.cardData[3].money=res.content
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },
            
      getGaslengendList(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGaslengend().then(res => {
			              this.loading = false;
				            if(res.code === 1){
				               console.log(res)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      }
    },
  components: { MyLegend:Legend },
  filters: {
    money(n) {
      let num = n.toFixed(2)
      let numInt = num.split('.')[0]
      let numFloat = num.split('.')[1]
      let numIntArr = numInt.split('')
      let numIntArrReverse = numIntArr.reverse()
      let arr = []
      numIntArrReverse.map((item, index) => {
        arr.push(item)
        if ((index + 1) % 3 == 0) {
          arr.push(',')
        }
      })
      return arr.reverse().join('') + '.' + numFloat
    }
  },

}
</script>
<style scoped lang="scss">
.business-card{
	margin: 20px;
}
.card-title{
	 padding-bottom: 20px;
	 .business-icon{
	  border-radius: 50%;
	  height: 60px;
	  width: 60px;
	 }
 	 .business-name{
		  font-size: 16px;
	 }
}
.card-body-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  .card-body-item-name {
    font-size: 14px;
    color: #101010;
  }
  .card-body-item-intro {
    font-size: 14px;
    color: #c8c8c8;
    margin: 2px 0;
  }
  .card-body-item-money {
    font-size: 28px;
    color: #101010;
    font-weight: bold;
  }
  .el-button{
  	padding:8px 20px;
  }
}
</style>