<template>
  <div class="select_component">
    <el-select  v-model="getValue" clearable placeholder="请选择" @change="handleChange($event)">
      <el-option
        v-for="item in selectData"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>
<script>
  export default {
  	props: ['selectData','selectID'],
    data() {
      return {
          getValue:this.selectID
      }
    },
    methods:{
    	 handleChange(params){
           console.log(params)
           this.$emit('choonseGrageEvent',params)

      }
    },
    
  }
</script>
<style lang="scss">
.select_component .el-input__inner{
      height: 33px;
      line-height: 33px;
}
</style>
