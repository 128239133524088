<template>
  <div class="block">
    <el-date-picker
      v-model="value2"
      type="daterange"
      align="right"
      unlink-panels
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      style="width: 450px;"
      :picker-options="pickerOptions">
    </el-date-picker>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        pickerOptions: {
          shortcuts: [{
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          }, {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }]
        },
//      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
        value1: "",
        value2: "",
        startTime: "",
        endTime: ""
      };
    },
    created() {
    },
    computed: {},
    watch: {
      value2(value) {
//      console.log(value);
//      console.log(value[0]);
//      console.log(value[1]);
        this.startTime = value[0];
        this.endTime = value[1];

        this.$emit("timeValue", this.startTime, this.endTime);
      }
    }
  };
</script>
<style lang="scss">
.block{
	.el-range-editor.el-input__inner{
		padding: 0 10px;
	}
	.el-input__inner{
		height: 35px;
		line-height: 35px;
	}
}
</style>
