<template>
  <div class="app-main">
  	
  	<div class="head">
      <img src="../../assets/images/card.png" alt />
      <div class="money">
                         自有卡可分配余额：
        <!--<span>{{card.balance|money}}元</span>-->
        <span>{{this.allbalance}}元</span>
      </div>
      <div class="btns">
        <el-button plain round type="primary" size="mini" class="btnRight" @click.stop="addAllocation">添加{{this.outBalance}}</el-button>
        <el-button plain round type="danger" size="mini" class="btnRight"  @click.stop="rechargeBtn">充值</el-button>
        <el-button plain round type="danger" size="mini" class="btnRight" @click.stop="flowRecord">流水记录</el-button>
      </div>
    </div>

    <div class="body" style="margin: 20px;">
			      <el-radio-group v-model="cardType">
				        <el-radio-button label="我发出的油卡"></el-radio-button>
				        <el-radio-button label="我收到的油卡"></el-radio-button>
			      </el-radio-group>
      	     <div style="text-align: right;margin-top:-35px;font-size: 14px;">
			      	   <span v-show="cardType == '我发出的油卡'">我发出去的油卡总金额:{{this.IssueBalance}}（元）</span>
			      	   <span v-show="cardType == '我收到的油卡'">我收到的油卡总金额:{{this.receiveBalance}}（元）</span>
			      </div>
			      
			      <el-form inline class="my-form" v-show="cardType == '我发出的油卡'">
			        <el-form-item label="油卡编号：">
			          <el-input size="small" v-model="cardNumber" placeholder=""></el-input>
			        </el-form-item>

			        <el-form-item label="接收方：">
			          <el-input size="small" v-model="inputUsername" placeholder=""></el-input>
			        </el-form-item>
			        <el-form-item label="手机号：">
			          <el-input size="small" v-model="telPhoneNumber" placeholder=""></el-input>
			        </el-form-item>

			        <el-form-item>
			          <el-button size="small" type="primary" @click.stop="findData">查询</el-button>
			          <el-button size="small" @click.stop="resetBtn">重置</el-button>
			          <el-button size="small">导出</el-button>
			        </el-form-item>
			      </el-form>
			      
			      
			      <el-form inline class="my-form" v-show="cardType == '我收到的油卡'">
			        <el-form-item label="油卡编号：">
			          <el-input size="small" v-model="cardNumber" placeholder=""></el-input>
			        </el-form-item>
			        <el-form-item label="转卡方：">
			          <el-input size="small" v-model="inputUsernameshou" placeholder=""></el-input>
			        </el-form-item>
			        <el-form-item label="手机号：">
			          <el-input size="small" v-model="telPhoneNumbershou" placeholder=""></el-input>
			        </el-form-item>

			        <el-form-item>
			          <el-button size="small" type="primary" @click.stop="findData">查询</el-button>
			          <el-button size="small" @click.stop="resetBtn">重置</el-button>
			          <el-button size="small">导出</el-button>
			        </el-form-item>
			      </el-form>

  	        <div style="padding-top: 20px;">
  	        	<page-table :data="tableData" :columns="columns" :pagination="pagination" v-show="cardType == '我发出的油卡'" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
						      <el-table-column label="操作" align="center" style="width: 500px;">
				                <template slot-scope="scope">
							                <el-tag @click.stop="frozeBtn(scope.$index, scope.row)" class="btnlist" v-html="scope.row.fromUser.type=='司机' ? '转账金额':'分配金额'"></el-tag>
							                <el-tag @click.stop="runWater(scope.$index, scope.row)" class="btnlist">查看流水</el-tag>
						            </template>
						      </el-table-column>
				      </page-table>
				      <page-table :data="tableDataFir" :columns="columns1" :pagination="pagination" v-show="cardType == '我收到的油卡'" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
						      <el-table-column label="操作" align="center" style="width: 500px;">
				                <template slot-scope="scope">
							                <el-tag @click.stop="transferAccount(scope.$index, scope.row)" class="btnlist">转账</el-tag>
							                <el-tag @click.stop="widthDraw(scope.$index, scope.row)" class="btnlist">提现</el-tag>
							                <el-tag @click.stop="runWater(scope.$index, scope.row)" class="btnlist">查看流水</el-tag>
						            </template>
						      </el-table-column>
				      </page-table>
  	        </div>
       </div>
       
	    <v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" @update:dialogVisible="dialogVisibles" @update:confirmdialogVisible="confrmdialogVisibles">
		     	   <div class="dialog_content" v-show="modifyStatus">
		     	   	  <p>可分配余额：
		     	   	  	<span>¥{{this.allbalance.toFixed(2)}}元</span>
		     	   	  	<span>元</span>
		     	   	  </p>
			          <p class="dialog_list">
		     	   	  	<label>接收方：</label>
		     	   	  	<el-input placeholder="请输入用户手机号" v-model="acceptInputPhone" clearable maxlength="11"></el-input>
		     	   	  </p>
		     	   	  <p class="dialog_list">
		     	   	  	<label>*分配金额：</label>
		     	   	  	<el-input placeholder="请输入（单位:元）" v-model="DistributionInput" clearable ></el-input>
		     	   	  </p>
		     	   </div>
		     	   
		     	   
		     	   <div class="rechargeBalance" v-show="rechargeStatus">
		     	   	  <p>可分配余额:
		     	   	  	<span>¥{{this.allbalance.toFixed(2)}}</span>
		     	   	  	<span>元</span>
		     	   	  </p>
		     	   	  
		     	      <p class="dialog_list">
		     	   	  	<label>接收方：</label>
		     	   	    <span>{{this.dialogMoney}}</span>
		     	   	  </p>
		     	   	  
		     	   	  <p class="dialog_list">
		     	   	  	<label>手机号：</label>
		     	   	     <span>{{this.dialogTel}}</span>
		     	   	  </p>
		     	   	  
		     	   	  <p class="dialog_list">
		     	   	  	<label>*分配金额：</label>
		     	   	  	<el-input placeholder="请输入（单位：元）" v-model="oilCardDistribution" clearable></el-input>
		     	   	  </p>
		     	   </div>
		     	   
		     	   <div v-show="payStatus">
		     	   	  <p>
		     	   	    <label>{{this.outBalance}}金额：¥{{this.DistributionInput}}元</label>   	   	    
		     	   	  </p>
		     	      <p>
		     	      	<label>接收方：{{this.acceptInputPhone}}</label>
		     	      </p>
		     	   	   <p>
		     	   	   	<label>姓名：{{this.fpusername}}</label>
		     	   	   </p>
		     	   </div>
		     	   
		     	   <div class="rechargeBalance" v-show="unregistered">
		     	   	  <p>
		     	   	           该手机号尚未注册，请提醒对方注册【大件无忧共享加油】小程序后再转账/分配
		     	   	  </p>
		     	   </div>
		     	  
		     	   <div v-show="payTranferStatus">
		     	   	   <p>
		     	      	<label>转卡方：{{this.shdaoName}}</label>
		     	      </p>
		     	      <p>
		     	      	<label>接收方：</label>
		     	      	<el-input placeholder="请输入手机号" v-model="shdaojieshName" clearable maxlength="11"></el-input>
		     	      </p>
		     	  	  <p>
		     	   	    <label>金额：</label>
		     	   	    <el-input placeholder="请输入（单位：元）" v-model="shdaoBalance" clearable></el-input>
		     	   	  </p>
		     	   	  <p>
		     	   	    <span>手续费1%（最低1元），从本卡扣</span>
		     	   	  </p>
		     	  </div>
		     	  
		     	  <div v-show="payshoudaoStatus">
		     	   	  <p>
		     	   	    <label>金额：{{this.shdaoBalance}}元</label>
		     	   	  </p>
		     	      <p>
		     	      	<label>接收方：{{this.shdaojieshName}}</label>
		     	      </p>
		     	   	   <p>
		     	   	   	<label>姓名：{{this.fpusername}}</label>
		     	   	   </p>
		     	   </div>
		  </v-dialog>
  </div>
</template>
<script>
  import { getGasBillfreeBalance, oilcardList, getGasCard, FrozenCard, changeBalance, rechargeBalance, getGasBillIssueBalance, getGasBillreceviceBalance, getGasBillContract, oilCardsFind, oilcardDistributionBalance, oilcardtransferAccBalance, oilCardsFindshudao} from "@/api/card";
  import pageTable from '../../components/pageTable'
  import VDialog from '../../components/dialog'
  export default {
    name: 'manage',
    inject:['reload'],
    data() {
      return {
        cardType: '我发出的油卡',
        tableData: [],
        tableDataFir:[],
        columns: [
          {
            name: '油卡编号',
            value:'no'
          },
          {
            name: '接收方',
            value:'cardsRealname'
          },
          {
            name: '手机号码',
            value:'cardsMobile',
            width:''
          },
          {
            name: '油卡余额（元）',
            value:'balance',
            width:''
          }
        ],
        
        columns1: [
          {
            name: '油卡编号',
            value:'no'
          },
          {
            name: '转卡方',
            value:'cardsCompany'
          },
          {
            name: '手机号码',
            value:'cardsPhone',
            width:''
          },
          {
            name: '油卡余额（元）',
            value:'balance',
            width:''
          }
        ],
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size:0
        },
        loading: false,
	      dialogVisible: false,
	      dialogInfo:'',
	      allbalance:0,
	      IssueBalance:0,
	      receiveBalance:0,
	      modifyStatus:false,
	      rechargeStatus:false,
	      unregistered:false,
	      payStatus:false,
	      payTranferStatus:false,
	      payshoudaoStatus:false,
	      rechargeBalanceValue:'',
	      radio:'1',
	      cardNumber:'',
	      inputUsername:'',
	      telPhoneNumber:'',
        rechargeUserId:0,
        rechargeGasCardId:0,
        
        acceptInputPhone:'',
        DistributionInput:'',
        oilCardDistribution:'',
        
        giveAccept:2,
        dialogMoney:'',
	      dialogTel:'',
	      no:'',
	      userId:'',
			  gasCardId:'',
			  outBalance:"分配",
			  
			  fpgasCardId:0,
			  fpuserId:0,
			  fpPhoneNum:'',
			  fpusername:'',
			  remark:'',
			  fpBalanceuserId:0,
			  
			  shdaoName:'',
			  shdaojieshName:'',
			  shdaoBalance:'',
			  shdaogasID:'',
			  shdaouserID:'',
			  
			  findRunWaterUserId:'',
			  findRunWaterfromGasCardId:'',
			  findRunWatertoGasCardId:'',
			  
			  inputUsernameshou:'',
        telPhoneNumbershou:''
      }
    },
    methods: {
    	flowRecord(){
    		this.$router.push({path:'/oilCard-manage/waterRecord',query: {
					   gasCardId:this.gasCardId
		　    }})
    	},
    	
    	addAllocation(){
	    		this.dialogVisible=true
		      this.dialogInfo='添加'+this.outBalance
		      this.modifyStatus=true
		      this.rechargeStatus=false
		      this.unregistered=false
		      this.payStatus=false
		      this.payTranferStatus=false
		      this.payshoudaoStatus=false
    	},

    	rechargeBtn(){
    		 this.$router.push({path:'/oilCard-manage/applyRecharge',query: {
		　　　　   no:this.no,
			       userId:this.userId,
					   gasCardId:this.gasCardId
		　    }}) 	
    	},

    	findData(){
    	  if(this.cardType == '我收到的油卡'){
    	 	    this.giveAccept=1
    	 	     this.oilCardAllList(this.cardNumber, this.inputUsernameshou, this.telPhoneNumbershou, this.inputUsername, this.telPhoneNumber, this.giveAccept,this.pagination.pageSize,this.pagination.currPage)
    	  }else{
    	 		 this.giveAccept=2
    	 		 this.oilCardAllList(this.cardNumber, this.inputUsernameshou, this.telPhoneNumbershou, this.inputUsername, this.telPhoneNumber, this.giveAccept,this.pagination.pageSize,this.pagination.currPage)
    	  }
    	  
    	},
    	resetBtn(){
        var self=this
    	  self.reload () 
    	},
    	
      oilCardAllList(no, fromUsername, fromMobile, realname, mobile, giveAccept, size, page){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        oilcardList(no, fromUsername, fromMobile, realname, mobile, giveAccept, size, page).then(res => {
			              this.loading = false;
				            if(res.code === 1){
				            	if(this.giveAccept==2){
				            		this.tableData = res.content
				            	}else{
				            		this.tableDataFir= res.content
				            	}
				            	this.pagination.total = res.totalElements
				              this.pagination.size = res.totalPages	
				            }else if(res.code === 0){
				            	 if(this.giveAccept==2){
				            		 this.tableData = []
				            	 }else{
				            		 this.tableDataFir= []
				            	 }
				            	 this.pagination.total = res.totalElements
				               this.pagination.size = res.totalPages	
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

	    getGasBillfreeBalanceList(){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		           getGasBillfreeBalance().then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	 console.log(res)
			           	 	 this.fpgasCardId=res.content.gasCardId
										 this.fpPhoneNum=res.content.user.mobile
										 
										  this.findRunWaterUserId=res.content.user.userId
			                this.findRunWaterfromGasCardId=res.content.gasCardId
										 
			            	 this.allbalance=res.content.balance
			            	 console.log(this.allbalance)
			            	 this.no=res.content.no
							       this.userId=res.content.user.userId
									   this.gasCardId=res.content.gasCardId
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },
      
      getGasBillIssueBalanceAmount(){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		           getGasBillIssueBalance().then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	this.IssueBalance=res.content
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },
      
      getGasBillreceviceBalanceAmount(){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		           getGasBillreceviceBalance().then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	 this.receiveBalance=res.content
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },
      
      getGasBillContractList(){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		           getGasBillContract().then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	 if(res.content.type=='司机'){
			            	  	this.outBalance="转账"
			            	 }else{
			            	 		this.outBalance="分配"
			            	 }
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },
      
      oilCardsFindList(username){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		           oilCardsFind(username).then(res => {
		             this.loading = false;
			            if(res.code == 1){
			            	        console.log(res)
			                    	this.fpuserId=res.content.userId
			                      this.fpusername=res.content.realname
			                      this.modifyStatus=false
											      this.rechargeStatus=false
											      this.unregistered=false
											      this.payTranferStatus=false
											      this.payStatus=true
											      this.payshoudaoStatus=false
											      this.dialogInfo='请确定'+this.outBalance+'信息'
			                  
			            }else if(res.code == -1){
			            	
			            		      this.modifyStatus=false
											      this.rechargeStatus=false
											      this.unregistered=true
											      this.payStatus=false
											      this.payTranferStatus=false
											      this.payshoudaoStatus=false
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },

      oilCardsFindshudaoList(username){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		            oilCardsFindshudao(username).then(res => {
		             this.loading = false;
			            if(res.code == 1){
			            	        console.log(res)
			                    	this.shdaouserID=res.content.userId
			                      this.fpusername=res.content.realname
			                      this.modifyStatus=false
											      this.rechargeStatus=false
											      this.unregistered=false
											      this.payStatus=false
											      this.payTranferStatus=false
											      this.payshoudaoStatus=true
			                      
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },
      
      
      oilcardDistributionBalanceList(price, remark, fromGasCardId,toUserId){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		           oilcardDistributionBalance(price, remark, fromGasCardId,toUserId).then(res => {
		             this.loading = false;
		              console.log(res)
			            if(res.code == 1){
			                 this.$message({
								          message: '分配成功',
								          type: 'success'
								        })
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },


      oilcardtransferAccBalanceList(price, remark, fromGasCardId,toUserId){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		           oilcardtransferAccBalance(price, remark, fromGasCardId,toUserId).then(res => {
		             this.loading = false;
		              console.log(res)
			            if(res.code == 1){
			                 this.$message({
								          message: '转账成功',
								          type: 'success'
								        })
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      },

      frozeBtn(event, value){
      	this.fpBalanceuserId=value.user.userId
    	  this.dialogMoney= value.user.realname
	      this.dialogTel= value.user.mobile
      	this.dialogVisible=true
	      if(this.outBalance=="分配"){
	      	 this.dialogInfo='油卡分配'
	      }else{
	      	 this.dialogInfo='油卡转账'
	      }
	      this.modifyStatus=false
	      this.rechargeStatus=true
	      this.unregistered=false
	      this.payStatus=false
	      this.payTranferStatus=false
	      this.payshoudaoStatus=false
      },

      runWater(event,value) {
      	this.findRunWatertoGasCardId=value.gasCardId
        this.$router.push({path:'/oilCard-manage/manageDetail',query:{
        	toGasCardId:this.findRunWatertoGasCardId
       
       }})
      },

      transferAccount(event, value){
          	console.log(value)
          	this.shdaogasID=value.gasCardId
      	    this.shdaoName= value.user.realname
            this.dialogVisible=true
        		this.modifyStatus=false
			      this.rechargeStatus=false
			      this.unregistered=false
			      this.payStatus=false
			      this.payTranferStatus=true
			      this.payshoudaoStatus=false
   	
	      if(this.outBalance=="分配"){
	      	
	      	 this.dialogInfo='油卡分配'
	      }else{
	      	 this.dialogInfo='油卡转账'
	      }
      },
      
      widthDraw(){
      	this.$router.push({path:'/oilCard-manage/applywithdraw'}) 
      },
      
      pageSizeNum(value){
      	  console.log(value)
      	  this.pagination.pageSize=value
      	  this.oilInvoiceList(this.pagination.pageSize,this.pagination.currPage)
      },
      currentPageNum(event, value){
      	  console.log(event)
      	  this.pagination.currPage=event
      	  this.oilInvoiceList(this.pagination.pageSize,this.pagination.currPage)
      },
      
      
    	//子组件传 过来的 数据
	    dialogVisibles(v){
	      this.dialogVisible = v
	    },
	    confrmdialogVisibles(v){
	    	 if(this.modifyStatus==true){
	    	 	    if (this.acceptInputPhone=="" || this.DistributionInput=="") {
							      this.$message.error('有信息未填')
							      return
							}else if (this.DistributionInput > this.allbalance) {
								
							      this.$message.error('金额必须小于等于卡内余额')
							      return
							}else if (this.DistributionInput === 'NaN' || this.DistributionInput === ''){
								    this.$message.error('	金额必须大于0元')
							      return
							
							}else if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.acceptInputPhone))){
							   	 this.$message.error('手机号码不正确')
							      return
							
							}else if(this.fpPhoneNum==this.acceptInputPhone){
								
								   this.$message.error('自己不能给自己转账')
							      return
							}
 	 
              this.oilCardsFindList(this.acceptInputPhone)
	       	  	
//	      	 	  this.dialogVisible = v
	    	 	  
	    	 }else if(this.rechargeStatus==true){
	    	 	
		    	 	if(this.oilCardDistribution=="" || this.oilCardDistribution==null){
		    	 		
		    	 		  this.$message('充值金额不能为空！');
		    	 		  
		    	 	}else{
		    	 		
		    	 			if(this.outBalance=="分配"){
		    	 				
		    	 				  this.oilcardDistributionBalanceList(this.oilCardDistribution, this.remark, this.fpgasCardId, this.fpBalanceuserId)
		    	 			}else{
		    	 				
		    	 			  	this.oilcardtransferAccBalanceList(this.oilCardDistribution, this.remark, this.fpgasCardId, this.fpBalanceuserId)
		    	 			}
		 	         this.dialogVisible = v
		    	 	} 	
		    	 	
	    	 }else if(this.unregistered==true){

	    	 	   this.dialogVisible = v
	    	 	
	    	 }else if(this.payStatus==true){
	    	 	
	    	  	if(this.outBalance=="分配"){
	    	 			  this.oilcardDistributionBalanceList(this.DistributionInput, this.remark, this.fpgasCardId, this.fpuserId)
	    	 		
	    	  	}else{
	    	 		    this.oilcardtransferAccBalanceList(this.DistributionInput, this.remark, this.fpgasCardId, this.fpuserId)
	    	 	  }
	    	 	  this.dialogVisible = v
	    	 	  
	    	 }else if(this.payTranferStatus==true){
	    	 	
	    	 	   if (this.shdaojieshName=="" || this.shdaoBalance=="") {
							      this.$message.error('有信息未填')
							      return
							
							}else if (this.shdaoBalance === 'NaN' || this.shdaoBalance === ''){
								    this.$message.error('	金额必须大于0元')
							      return
							
							}else if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.shdaojieshName))){
							   	 this.$message.error('手机号码不正确')
							      return
							}

              this.oilCardsFindshudaoList(this.shdaojieshName)
//	    	 	  this.oilcardtransferAccBalanceList(this.DistributionInput, this.remark, this.fpgasCardId, this.fpuserId)
	    	 }else if(this.payshoudaoStatus==true){
	    	 	
	    	 	  this.oilcardtransferAccBalanceList(this.shdaoBalance, this.remark, this.shdaogasID, this.shdaouserID)
	    	   	this.dialogVisible = v
	    	 }
	    	 
	    }
    },
    components: { pageTable,VDialog },
    watch: {
      cardType(value){
      	 console.log(value)
      	 if(value=="我收到的油卡"){
    	 	    this.giveAccept=1
    	 	    
    	   }else{
    	 		  this.giveAccept=2
    	 		 
    	   }
          console.log(this.giveAccept)
          this.oilCardAllList(this.cardNumber, this.inputUsernameshou, this.telPhoneNumbershou, this.inputUsername, this.telPhoneNumber, this.giveAccept,this.pagination.pageSize,this.pagination.currPage)
      	  
      }
    },

    filters: {
      money(n) {
        let num = n.toFixed(2)
        let numInt = num.split('.')[0]
        let numFloat = num.split('.')[1]
        let numIntArr = numInt.split('')
        let numIntArrReverse = numIntArr.reverse()
        let arr = []
        numIntArrReverse.map((item, index) => {
	          arr.push(item)
	          if ((index + 1) % 3 == 0) {
	            arr.push(',')
	          }
        })
        return arr.reverse().join('') + '.' + numFloat
      }			  
	
        
    },
    mounted(){
       this.oilCardAllList(this.cardNumber, this.inputUsernameshou, this.telPhoneNumbershou, this.inputUsername, this.telPhoneNumber, this.giveAccept, this.pagination.pageSize,this.pagination.currPage)
       this.getGasBillfreeBalanceList()
       this.getGasBillIssueBalanceAmount()
       this.getGasBillreceviceBalanceAmount()
       this.getGasBillContractList()
       
    }
  }
</script>
<style lang="scss" scoped>
  .head {
    background: #fff;
    display: flex;
    padding-left: 20px;
    align-items: center;
    height: 80px;
    margin: 20px;
    .money {
      vertical-align: baseline;
      margin: 0 20px;
      span {
        font-size: 20px;
      }
    }
  }
  .el-form-item {
    margin-top: 22px;
    margin-bottom: 0;
  }
  .all-money {
    margin: 5px 0;
    font-size: 14px;
    color: #817979;
    text-align: right;
  }
  .dialog_content p{
  	padding-bottom: 15px;

  }
  .dialog_list{
  	display: flex;
    label{
  		width: 15%;
  		line-height: 35px;
  	}
  }
  .btnRight{
  	margin-right: 20px;
  }
  
</style>