<template>
  <div class="app-main">
     <div class="body mgin">
			  	<div class="body_kuang">
				    <el-form inline class="my-form">
			      	<el-form-item label="转卡时间：">
				         <time-picker @timeValue="getValue(arguments)"></time-picker>
				      </el-form-item>
				      <el-form-item label="手机号：">
			          <el-input size="small" placeholder="" v-model="telphone"></el-input>
			        </el-form-item>
				      <el-form-item label="转卡单位：">
			          <el-input size="small" placeholder="" v-model="fromCompanyName"></el-input>
			        </el-form-item>
			        <el-form-item label="转卡账户：">
			          <el-input size="small" placeholder="" v-model="fromGasCardNo"></el-input>
			        </el-form-item>
			        <el-form-item label="接收对象：">
			          <el-input size="small" placeholder="" v-model="toUserRealName"></el-input>
			        </el-form-item>
			        <el-form-item label="接收卡号：">
			          <el-input size="small" placeholder="" v-model="toGasCarNo"></el-input>
			        </el-form-item>
						  <el-form-item>
				        <el-button size="small" type="primary" @click.stop="findData">查询</el-button>
				        <el-button size="small" @click.stop="resetBtn">重置</el-button>
				        <el-button size="small">导出Excel</el-button>
				      </el-form-item>
				    </el-form>
			  	</div>
		</div>
		
		<div class="body mgin" style="padding-top: 0;">
			    <page-table :data="tableData" :columns="columns" :pagination="pagination" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
			      <el-table-column label="操作" align="center">
			        <template slot-scope="scope">
			         <el-tag @click.stop="cardDetail(scope.$index, scope.row)" class="btnlist">查看</el-tag>
			        </template>
			      </el-table-column>
			    </page-table>
	 </div>
	 
  	<v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" :dialogFooter='dialogFooter' @update:dialogVisible="dialogVisibles">
  	 		   <div class="dialog_content" v-show="oildetailStatus" v-for="(item, index) in this.cardRecord" :key="index">
	     	   	  <p>账户充值-：转给{{item.payment}}</p>
	     	      <p>转账金额：¥{{item.price.toFixed(2)}}元</p>
	    	   	  <p>当前状态：
	   	   	   	     <span v-if="item.state==1">已成功</span>
				           <span v-if="item.state==0">未完成</span>
	    	   	  </p>
	    	   	  <p>接收单位：{{item.toUser.realname}}</p>
	    	   	  <p>接收账户：{{item.toGasCard.no}}</p>
	    	   	  <p>手续费：¥{{item.servicePrice.toFixed(2)}}元</p>
	    	   	  <p>转卡备注：{{item.remark}}</p>
	    	   	  <p>转卡时间：{{item.addTime}}</p>
	    	   	  <p>转卡账户：{{item.fromUser.realname}}</p>
	    	   	  <p>转卡单号：{{item.no}}</p>
	     	  </div>
   </v-dialog>
  </div>
</template>
<script>
	import { getGasTransferList } from "@/api/transfer";
  import pageTable from '../../components/pageTable'
  import timePicker from '../../components/timePicker'
  import VDialog from '../../components/dialog'
  export default {
    name: 'transferdetail',
    data() {
      return {
        tableData: [],
        cardRecord:[],
        inject:['reload'],
        columns: [
          {
            name: '转卡时间',
            value: 'addTime'
          },
          {
            name: '转账金额（元）',
            value: 'price'
          },
          {
            name: '手续费（元）',
            value: 'servicePrice'
          },
          
          {
            name: '转卡人',
            value: 'transferName'
          },
          {
            name: '转卡手机号',
            value: 'transferPhoneNum'
          },
          {
            name: '转卡账户',
            value: 'transferfromUser'
          },
          {
            name: '接收人',
            value: 'transfertoUser'
          },
          {
            name: '接收手机号',
            value: 'transfertoPhoneNum'
          },
          {
            name: '接收卡号',
            value: 'transfertoGasCard'
          },

          {
            name: '状态',
            value: 'transferstate'
          }
        ],
        loading:false,
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size: 0
        },
        dialogVisible:false,
        oildetailStatus:false,
        dialogInfo:'',
        dialogFooter:false,
        startTime:'',
        endTime:'',
        telphone:'',
        fromCompanyName:'',
        fromGasCardNo:'',
        toUserRealName:'',
        toGasCarNo:''
        
      }
    },
    methods:{
    	getValue(data){
 	      this.startTime = data[0]
        this.endTime = data[1]
    	},
    	findData(){
    	     this.getGasTransferFun(this.startTime, this.endTime, this.telphone, this.fromCompanyName, this.fromGasCardNo, this.toUserRealName, this.toGasCarNo, this.pagination.pageSize,this.pagination.currPage)
    	},
    	resetBtn(){
        var self=this
    	  self.reload() 
    	},
    	getGasTransferFun(startAddTime, endAddTime, fromMobile, fromCompanyName, fromGasCardNo, toUserRealName, toGasCarNo, size, page){
 	        return new Promise((resolve, reject) => {
       	  this.loading = true;
		        getGasTransferList(startAddTime, endAddTime, fromMobile, fromCompanyName, fromGasCardNo, toUserRealName, toGasCarNo, size, page).then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	 this.tableData = res.content
                     console.log('909090909')
			            	 console.log(this.tableData)
                     this.pagination.total = res.totalElements
				             this.pagination.size = res.totalPages	
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		          })
		      })
      },
       pageSizeNum(value){
      	  console.log(value)
      	  this.pagination.pageSize=value
      	  this.getGasTransferFun(this.startTime, this.endTime, this.telphone, this.fromCompanyName, this.fromGasCardNo, this.toUserRealName, this.toGasCarNo, this.pagination.pageSize,this.pagination.currPage)
      },
      currentPageNum(event, value){
      	  console.log(event)
      	  this.pagination.currPage=event
      	  this.getGasTransferFun(this.startTime, this.endTime, this.telphone, this.fromCompanyName, this.fromGasCardNo, this.toUserRealName, this.toGasCarNo, this.pagination.pageSize,this.pagination.currPage)
      },
      cardDetail(event, value){
      	this.cardRecord=[value]
      	this.dialogVisible=true
        this.oildetailStatus=true
        this.dialogInfo='转卡详情'
      },
           //子组件传 过来的 数据
		  dialogVisibles(v){
		      this.dialogVisible = v
		  }
    },
    components: { pageTable, timePicker, VDialog },
    created() {
      // document.title = '充值记录'
      this.$store.dispatch('SetNavbar', [{ meta: { title: '管理中心' }, path: '/oilCard-manage/index' }, { meta: { title: '油卡管理' }, path: '/oilCard-manage/manage' }, { meta: { title: '流水记录' }, path: '/oilCard-manage/flow-detail' }, { meta: { title: '充值记录' } }])
    },
    mounted(){
       this.getGasTransferFun(this.startTime, this.endTime, this.telphone, this.fromCompanyName, this.fromGasCardNo, this.toUserRealName, this.toGasCarNo, this.pagination.pageSize,this.pagination.currPage)
    }
  }
</script>
<style scoped lang="scss">
.el-form-item{
   margin:0 35px 0px 0;
}
</style>