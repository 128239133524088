<template>
 <div>
 	    <div class="vol-data">
	      <div class="charts" style="width:'100%',height:'6.54rem'">
	        <div class="charts-title">
	                     加油金额
	        </div>
	        <div id="myChart" :style="{width:'100%',height:'300px'}"></div>
	      </div>
	    </div>
 </div>
</template>
<script>
import echarts from 'echarts'
export default {
  name: 'legend',
  data () {
    return {
    	
    }
  },
  mounted () {
    /*ECharts图表*/
    var myChart = echarts.init(document.getElementById('myChart'))
    myChart.setOption({
    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'cross',
            label: {
                backgroundColor: '#6a7985'
            }
        }
    },
    legend: {
        data: ['大件无忧加油金额']
    },
//  toolbox: {
//      feature: {
//          saveAsImage: {}
//      }
//  },
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: [
        {
            type: 'category',
            boundaryGap: false,
            data: ['2019-12-01', '2019-12-05', '2019-12-10', '2019-12-15', '2019-12-20', '2019-12-25', '2019-12-30']
        }
    ],
    yAxis: [
        {
            type: 'value'
        }
    ],
    series: [
        {
            name: '金额',
            type: 'line',
            stack: '总量',
            label: {
//              normal: {
//                 // show: true,
//                  position: 'top'
//              }
            },
            data: [800, 3500, 1000, 1500, 2000, 2500, 3000]
        }
    ],

    series: [
        {
            name: '大件无忧加油金额',
            type: 'line',
            stack: '总量',
            data: [120, 132, 101, 134, 90, 230, 210]
        },
      

    ]
    })
  },
  methods:{
  	
  }
}
</script>
<style lang="scss">
	.charts-title{
	    color: orange;
	    font-size: 18px;
		
	}
</style>
