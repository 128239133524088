<template>
	<div class="app-main">
	  <div class="body">                    
		    <el-form inline class="my-form">
    		 <el-form-item label="交易时间：">
		         <time-picker @timeValue="getValue(arguments)"></time-picker>
		      </el-form-item>
	
		      <el-form-item label="手机号：">
		          <el-input size="small" placeholder="" v-model="userRealName"></el-input>
		      </el-form-item>
		      <el-form-item label="交易类型：">
		        <v-select :selectData="oilCardStatus" :selectID="selectID" @choonseGrageEvent="chooseTradeType"></v-select> 
		      </el-form-item>
		      <el-form-item label="交易单号：">
		          <el-input size="small" placeholder="" v-model="tradeNo"></el-input>
		      </el-form-item>
		      <!--<el-form-item label="资金方向：">
		            <v-select :selectData="oilCardBalanceStatus" :selectID="selectBalanceID" @choonseGrageEvent="chooseBalanceGO"></v-select> 
		      </el-form-item>-->
		      <el-form-item>
		        <el-button size="small" type="primary" @click.stop="findData">查询</el-button>
		        <el-button size="small" @click.stop="resetBtn">重置</el-button>
		        <el-button size="small" placeholder="">导出</el-button>
		      </el-form-item>
		    </el-form>
       </div>
       
       <div class="body m-t20">
       	    <page-table :data="tableData" :columns="columns" :pagination="pagination" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
		      <el-table-column label="操作" align="center">
		        <template slot-scope="scope">
	                <el-tag @click.stop="editOildetail(scope.$index, scope.row)" class="btnlist">详情</el-tag>
		        </template>
		      </el-table-column>
		    </page-table>
       </div>
       
	    <v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" :dialogFooter='dialogFooter' @update:dialogVisible="dialogVisibles">
	     	   <div class="dialog_content" v-show="oildetailStatus" v-for="item in this.tableDataDeail" >
	     	   	  <p>交易时间:{{item.updateTime}}</p>
	    	   	  <p>交易单号:{{item.no}}</p>
	     	      <p>交易类型:{{item.category}}</p>
	     	      <p>油卡号码:{{item.user.mobile}}</p>
	    	   	  <p>交易金额:
	    	   	  	     <span v-if="item.outlay > 0">¥{{item.outlay.toFixed(2)}}元</span>
                         <span else>¥{{item.receive.toFixed(2)}}元</span>
	    	   	  </p>
	    	   	  <p>操作用户:
	    	   	   {{item.user.mobile}} 
	    	   	  </p>
	    	   	  <p>资金方向:
	    	   	     <span v-if="item.outlay > 0">支出</span>
		             <span v-else-if="item.receive> 0">收入</span>
		             <span v-else-if="item.outlay== 0 && item.receive== 0"></span>
	    	   	  </p>
	     	   </div>
	    </v-dialog>
  </div>
</template>
<script>
  import { getGasflowRecord } from "@/api/card";
  import pageTable from '../../components/pageTable'
  import VDialog from '../../components/dialog'
  import VSelect from '../../components/select'
  import timePicker from '../../components/timePicker'
  export default {
    name: 'addOildetail',
    inject:['reload'],
    data() {
      return {
        tableData: [],
        tableDataDeail:[],
        oilCardStatus: [{
          value: '分配',
          label: '分配'
        }, {
          value: '消费',
          label: '消费'
        }, {
          value: '转账',
          label: '转账'
        }, {
          value: '充值',
          label: '充值'
        }, {
          value: '提现',
          label: '提现'
        }],
//
//      oilCardBalanceStatus: [{
//        value: 1,
//        label: '支出'
//      }, {
//        value: 2,
//        label: '收入'
//      }],
        columns: [
          {
            name: '交易时间',
            value: 'addTime'
          },
          {
            name: '交易单号',
            value: 'no'
          },
          {
            name: '交易类型',
            value: 'category',
            width: '180'
          },
//        {
//          name: '油卡号码',
//          value: 'addoilTelphoneflow'
//        },
          {
            name: '交易金额（元）',
            value: 'oilCardflowBalance'
          },
          {
            name: '操作用户',
            value: 'oilCardFlow'
          },
          {
            name: '资金方向',
            value: 'oilCardGoflow',
            width: '280'
          }
  
        ],
        loading:false,
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size: 0
        },
        dialogVisible:false,
        oildetailStatus:false,
        dialogInfo:'',
        dialogFooter:false,
        gasCardId:0,
        
        userRealName:'',
        selectID:'',
        tradeNo:'',
//      selectBalanceID:1,
        startAddTime:'',
        endAddTime:'',
      }
    },
    components: { pageTable,VDialog,VSelect,timePicker }, 
    created() {
      document.title = '加油订单'
      this.$store.dispatch('SetNavbar', [{ meta: { title: '管理中心' }, path: '/oilCard-manage/index' }, { meta: { title: '油卡管理' }, path: '/oilCard-manage/manage' }, { meta: { title: '加油订单' } }])
    },
    methods:{
    	getValue(data){
 	      this.startAddTime = data[0]
          this.endAddTime = data[1]
    	},
    	findData(){
    		  this.oilOrderList(this.gasCardId, this.startAddTime, this.endAddTime, this.userRealName, this.selectID, this.tradeNo,this.pagination.pageSize,this.pagination.currPage)
    	},
    	resetBtn(){
    	  var self=this
    	  self.reload () 
    	},
	    oilOrderList(gasCardId, startAddTime, endAddTime, userRealname, category, no, size, page){
	 	    return new Promise((resolve, reject) => {
	       	this.loading = true;
	        getGasflowRecord(gasCardId, startAddTime, endAddTime, userRealname, category, no, size, page).then(res => {
	             this.loading = false;
		            if(res.code === 1){
		            	this.tableData = res.content
		            	this.pagination.total = res.totalElements
				        this.pagination.size = res.totalPages	
		            }
		            resolve(res)
	         }).catch(error => {
	         	   this.loading = false;
	             reject(error)
	         })
	      })
      },
      chooseTradeType(value){
           this.selectID=value
      },
//    chooseBalanceGO(value){
//    	  this.selectBalanceID=value
//    	  console.log(this.selectBalanceID)
//    },
      
      pageSizeNum(value){
      	  console.log(value)
      	  this.pagination.pageSize=value
      	   this.oilOrderList(this.gasCardId, this.startAddTime, this.endAddTime, this.userRealName, this.selectID, this.tradeNo,this.pagination.pageSize,this.pagination.currPage)
      },
      currentPageNum(event, value){
      	  console.log(event)
      	  this.pagination.currPage=event
      	   this.oilOrderList(this.gasCardId, this.startAddTime, this.endAddTime, this.userRealName, this.selectID, this.tradeNo,this.pagination.pageSize,this.pagination.currPage)
      },
      

      refundAmount(){
      	console.log(2222222)
      },
      editOildetail(event, value){
        this.tableDataDeail=[value]
      	this.dialogVisible=true
        this.oildetailStatus=true
        this.dialogInfo='流水记录详情'
        this.dialogFooter=false
      },
      //子组件传 过来的 数据
	  dialogVisibles(v){
	    this.dialogVisible = v
	  }
    },
    mounted(){
       this.gasCardId = this.$route.query.gasCardId
       console.log(this.gasCardId)
       this.oilOrderList(this.gasCardId, this.startAddTime, this.endAddTime, this.userRealName, this.selectID, this.tradeNo,this.pagination.pageSize,this.pagination.currPage)
    }
  }
</script>
<style scoped lang="scss">
.el-form-item{
   margin:0 45px 10px 0;
}
.dialog_content p{
	line-height: 25px;
}
</style>